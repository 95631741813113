$(document).ready(function(){

  $(document).on("click", "#confirm_all_users", function(){
    $(".price_list_confirm_all").prop('checked', this.checked);
    if (this.checked){
      $(".confirm_user_hourly_rate").parent().find('.select_text').addClass('hide');
      $(".confirm_user_hourly_rate").parent().find('.unselect_text').removeClass('hide');
    }
    else{
      $("#ckbCheckAll").prop('checked', this.checked);
      $(".confirm_user_hourly_rate").parent().find('.unselect_text').addClass('hide');
      $(".confirm_user_hourly_rate").parent().find('.select_text').removeClass('hide');
    }
  });

  $(document).on("click", ".add_product_attachment", function(){
    var id = getId(this.id);
    $("#product_attachment_" + id).removeClass("hide");
  });

  $(document).on("click", ".hourly_rate_confirm", function(){
    var id = getId(this.id);
    //if checked
    if ($(this).is(":checked")){
      // update to unselect text
      set_unselect_text("#select_text_" + id, "#unselect_text_" + id);
    }
    else {
      // update to select text
      set_select_text("#select_text_" + id, "#unselect_text_" + id);
    }
  });

  $(document).on("click", ".check_price_list", function(){
    var price_list = $("input:radio.check_price_list:checked").val();
    if (price_list == "YES"){
      $("#price_list_without_user_status").addClass("hide");
      $("#price_list_with_user_status").removeClass("hide");
    } else {
      $("#price_list_with_user_status").addClass("hide");
      $("#price_list_without_user_status").removeClass("hide");
    }
  });

  $(document).on("click", ".model_agreement_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#model_agreement_name_" + id).removeAttr('disabled');
    $("#model_agreement_attachment_" + id).removeAttr('disabled');
    $("#model_agreement_info_" + id).removeClass('hide');
  });

  $(document).on("click", ".add_model_agreement",  function() {
    var id = getId(this.id);
    var total_added_agreements = $("#product_model_agreement_" + id).find($("input")).length/2;
    var count = ++total_added_agreements;
    $('#input_add_model_agreement_' + id).append('<div class="added_model_agreement" id="added_model_agreement_wrapper_' + count + '"><div class="row"><div class="col-12 col-sm-5 form-group"><input type="text" id="input_add_name_'+count+'" name="model_agreement[agreement_'+ count +'][name]" class="form-control" placeholder = "Name of the model agreement"/></div><div class="col-12 col-sm-5 form-group"><input type="text" id="input_add_working_hours_'+count+'" name="model_agreement[agreement_'+ count +'][attachment]" class="form-control" placeholder = "Attachment" /></div><div class="col-12 col-sm-2 pt-md-2 form-group"><a href="javascript:void(0)" class="link-14 text-blue remove_fields dynamic remove_model_agreement" id="remove_model_agreement_' + count + '"><i class="removeIcon"></i>Remove</a></div></div></div>')
  });

  $(document).on("click",".remove_model_agreement", function() {
    var id = getId(this.id);
    $("#added_model_agreement_wrapper_"+ id).remove();
  });

  $(document).on("click", ".estimate_working_time", function(){
    var estimate_working_time = $("input:radio.estimate_working_time:checked").val();
    if (estimate_working_time == "NO"){
      $(".price_validity").addClass("hide");
    } else {
      $(".price_validity").removeClass("hide");
    }
  });

  $(document).on("click change", ".product_price_without_vat", function(){
    var id = getId(this.id);
    var total_amount_without_vat = $("#product_price_without_vat_" + id).val();
    var vat = $('#product_vat_rate_' + id).val();
    if (vat) {
      var with_vat_val = total_amount_without_vat * (1+(checkNumericValue(vat)/100));
      if (with_vat_val){
        $('#product_price_with_vat_' + id).val(checkNumericValue(with_vat_val));
      }
    }
  });

  $(document).on("change", ".product_vat_rate", function(){
    var id = getId(this.id);
    var product_vat_rate = $('#product_vat_rate_' + id).val();
    var product_price_without_vat = $('#product_price_without_vat_' + id).val();
    if (product_price_without_vat) {
      var product_price_with_vat = product_price_without_vat * (1+(checkNumericValue(product_vat_rate)/100));
      if (product_price_with_vat){
        $('#product_price_with_vat_' + id).val(checkNumericValue(product_price_with_vat));
      }
    }
  });

  $(document).on("click change", ".product_price_with_vat", function(){
    var id = getId(this.id);
    var total_amount_with_vat = $('#product_price_with_vat_' + id).val();
    var vat = $('#product_vat_rate_' + id).val();
    if (vat) {
      var without_vat_val = total_amount_with_vat / (1+(checkNumericValue(vat)/100));
      if (without_vat_val){
        $('#product_price_without_vat_' + id).val(checkNumericValue(without_vat_val));
      }
    }
  });

  $(document).on("click", ".product_action_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#product_action_name_" + id).removeAttr('disabled');
    $("#product_action_working_hours_" + id).removeAttr('disabled');
    $("#product_action_info_" + id).removeClass('hide');
  });

  $(document).on("click", ".save_product_register", function(){
    $(".save_product_details").click();
  });

  $(document).on("click", ".add_product_action",  function() {
    var id = getId(this.id);
    var total_added_actions = $("#product_service_action_" + id).find(':input').length/2;
    var count = ++total_added_actions;
    $('#input_add_product_action_' + id).append('<div class="added_product_action" id="added_product_action_wrapper_' + count + '"><div class="row"><div class="col-12 col-sm-4 form-group"><input type="text" id="input_add_name_'+count+'" name="product_action[action_'+ count +'][name]" class="form-control" placeholder = "Name of action"/></div><div class="col-12 col-sm-4 form-group"><input type="text" id="input_add_working_hours_'+count+'" name="product_action[action_'+ count +'][working_hours]" class="form-control" placeholder = "Working hours" /></div><div class="col-12 col-sm-2 pt-md-2 form-group"><a href="javascript:void(0)" class="link-14 text-blue remove_fields dynamic remove_product_action" id="remove_product_action_' + count + '"><i class="removeIcon"></i>Remove</a></div></div></div>')
  });
  $(document).on("click",".remove_product_action", function() {
    var id = getId(this.id);
    $("#added_product_action_wrapper_"+ id).remove();
  });

  $(document).on("click", ".product_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#product_name_" + id).removeAttr('disabled');
    $("#product_info_" + id).removeClass('hide');
  });

  $(document).on('click', '.product_remove', function(){
    var id = getId(this.id);
    $("#" + id).remove();
  });

  $(document).on("click", ".show_drop_down_menu", function(){
    var id = getId(this.id);
    var parentEl = $(this).parent().closest('.drop_down_data_list');
    $(parentEl).find('.set_title_name').toggleClass("hide");
    // $(parentEl).find('.show_upside_arrow').toggleClass("hide");
    $('#display_drop_down_data_' + id).toggleClass("hide");
  });

  //show action form on clicking edit btn
  $(document).on("click", ".action_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    var pricing_base = $("#confirm_pricing_base_" + id).val();
    if (pricing_base == "ACTION") {
      $("#action_price_" + id).removeAttr('disabled');
    }
    $("#action_name_" + id).removeAttr('disabled');
    $("#action_vat_combo_" + id).removeClass('hide');    
    $("#action_info_" + id).removeClass('hide');
    $("#time_base_billing_" + id).removeAttr('disabled');
    $("#action_base_billing_" + id).removeAttr('disabled');
    $("#action_tax_rate_" + id).removeAttr('disabled');
  });

  $(document).on('click', '.action_remove', function(){
    var id = getId(this.id);
    $("#" + id).remove();
  });

  $(document).on("click", ".action_cancel", function(){
    var id = getId(this.id);
    $("#product_action_info_" + id).addClass('hide');
  });

  $(document).on("click", ".model_agreement_cancel", function(){
    var id = getId(this.id);
    $("#model_agreement_info_" + id).addClass('hide');
  });

  $(document).on("click", ".time_base_billing", function(){
    var id = getId(this.id);
    $("#confirm_pricing_base_" + id).val("TIME");
    $("#time_base_billing_" + id).addClass('btn-primary').removeClass('btn-gray');
    $("#action_base_billing_" + id).removeClass('btn-primary').addClass('btn-gray');
  });

  $(document).on("click", ".action_base_billing", function(){
    var id = getId(this.id);
    $("#action_price_" + id).removeAttr('disabled');
    $("#confirm_pricing_base_" + id).val("ACTION");
    $("#time_base_billing_" + id).removeClass('btn-primary').addClass('btn-gray');
    $("#action_base_billing_" + id).addClass('btn-primary').removeClass('btn-gray');
  });

  $(document).on("click", ".confirm_time", function(){
    $("#pricing_base_confirm").val("TIME");
    $(".confirm_time").addClass('btn-primary').removeClass('btn-gray');
    $(".confirm_action").removeClass('btn-primary').addClass('btn-gray');
  });

  $(document).on("click", ".confirm_action", function(){
    $("#pricing_base_confirm").val("ACTION");
    $(".confirm_time").removeClass('btn-primary').addClass('btn-gray');
    $(".confirm_action").addClass('btn-primary').removeClass('btn-gray');
  });

  $(document).on("click", ".save_action_register", function(){
    $("#save_action_details").click();
  });

  //show expense form on clicking edit btn
  $(document).on("click", ".expense_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#expense_price_" + id).removeAttr('disabled');
    $("#expense_name_" + id).removeAttr('disabled');
    $("#expense_vat_combo_" + id).removeClass('hide');
    $("#expense_info_" + id).removeClass('hide');
    $("#expense_tax_rate_" + id).removeAttr('disabled');
  });

  $(document).on('click', '.expense_remove', function(){
    var id = getId(this.id);
    $("#" + id).remove();
  });

  $(document).on("click", ".save_expense_register", function(){
    $("#save_expense_details").click();
  });

});

function getId(id){
  var idArr = id.split("_");
  return idArr[idArr.length - 1];
}

function checkNumericValue(number, decimal_points = 2) {
  if (number){
    return parseFloat(parseFloat(number.toString().replace(' ', '').replace(",", ".")).toFixed(decimal_points));
  }
  else{
    return 0;
  }
}

//show unselect text, hide select text
function set_unselect_text(selectId, unselectId) {
  $(selectId).addClass('hide');
  $(unselectId).removeClass('hide');
}

//show select text, hide unselect text
function set_select_text(selectId, unselectId) {
  $(selectId).removeClass('hide');
  $(unselectId).addClass('hide');
}