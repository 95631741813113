$(document).on('turbolinks:load', function () {

  //Paginations
  if ($('.pagination').length) {
    $(window).scroll(function() {
      var url = $('.pagination .next_page').attr('href');
      if (url && $(window).scrollTop() > $(document).height() - $(window).height() - 50) {
        $('.pagination').text("Please Wait...");
        return $.getScript(url);
      }
    });
    return $(window).scroll();
  }

  // after adding customer-assoc and click on add_field link to add render customer fields and then remove the link
  $('#customers').on('cocoon:after-insert', function(e, insertedItem) {
    $(insertedItem).children(".add_fields").click();
    $(insertedItem).children(".add_fields").remove();
    var customer_fields_ele = $(".customer_fields")[$(".customer_fields").length - 1];
    var last_customer_assoc_ele = $(".customer-association-fields")[$(".customer-association-fields").length - 1];
    var actual_customer_pos = $(last_customer_assoc_ele).find(".actual_customer_info");
    $(actual_customer_pos).after($(customer_fields_ele));
    if ($("#default_customer").find(".nested-fields:visible").length == 0){
      $(last_customer_assoc_ele).appendTo($("#default_customer"));
      $("#default_customer").find(".seperator").remove();
      $("#default_customer").find('.primary_customer').val('true');
    }
  });

  if ($(".remove_fields").length == 1){
    $(".remove_fields").addClass("hide");
  }else if ($(".remove_fields").length >= 1){
    var remove_fields  = document.getElementsByClassName("remove_fields")
    remove_fields[0].className = remove_fields[0].className.replace(/\bremove_fields\b/g, "remove_fields hide")
  }else{
    if ($(".remove_fields").hasClass("hide")){
      $(".remove_fields").removeClass("hide");
    }
  }

  // remove hr for default customer
  $("#default_customer").find(".seperator").remove();
    
  //if new defaut customer is added then click on add field to render customer fields, remove add link and remove 'remove' link
  if ($(".primary_customer_association").find(".customer-association-fields").length > 1){
  }
  else {
    if ($(".customer-association-fields").find(".add_fields").length > 1){
      document.getElementById("new_customer_fields").click();
      $(".customer-association-fields").find(".add_fields").hide();
      $(".primary_customer_association").find(".remove_fields").hide();
    }
  }

  $(".add_new_customer").on('click',function(){
    document.getElementById("new_customer_fields").click();
  })

  //for default new customer, set the customer fields under nested customer assoc form
  if ($("#default_customer").children(".customer_fields").length == 1){
    var customer_fields_ele = $("#default_customer").children(".customer_fields");
    var customer_assoc_ele = $("#default_customer").children(".customer-association-fields");
    var actual_customer_pos = $(customer_assoc_ele).find(".actual_customer_info");
    $(actual_customer_pos).after($(customer_fields_ele));
  }

  $(document).on("click", ".openCloseDetails", function(){
    var toggle_id = $(this).data('show_modal_id');
    $(this).parent().parent().find("#"+toggle_id).toggleClass("hide");
    $(this).find(".show_selected_detail_arrow").toggleClass("hide");
  });

  $(".addThousandSeparators").focusout(function() {
    $(this).val(addThousandSeparators($(this).val()));
  });

  $("#presentation_text").on('keyup',function(){
    $(this).css('height','auto');
    $(this).height(this.scrollHeight);
  })

  $(document).on('click', '#save_send_feedback_modal_form_btn', function(){
    var browser_data;
    var browser_parser = new UAParser();
    var browser_global_data = browser_parser.getResult();
    var screen_size;

    if(window){
      if(window.screen){
        screen_size = window.screen.width + 'x' + window.screen.height
      }
    }

    browser_data = {
                    'browser_name': browser_global_data.browser.name,
                    'browser_version': browser_global_data.browser.version,
                    'os_name': browser_global_data.os.name,
                    'os_version': browser_global_data.os.version,
                    'screen_size': screen_size,
                    'device_type': browser_global_data.device.type
                  }

    $('#get_current_user_browser_details').val(JSON.stringify(browser_data));
    $('#get_current_page_feedback_url').val(window.location.href);
    $('#save_user_feedback_form_id').closest('form').submit();
  });

  common_events();

  $(document).on("change", ".check_feedback_phase_attachment_type", function(){
    var phase_type = $(this).val();
    $('.attach_current_page_location').addClass('hide');
    $('.attach_feedback_resources').addClass('hide');
    if (phase_type == "YES"){
      $('.attach_current_page_location').removeClass('hide');
    }else{
      $('.attach_feedback_resources').removeClass('hide');
    }
  });

  $(document).on("change", ".copy_same", function(){
    $('.' + $(this).data('class_name')).val($(this).val());
    if ($(this).data('trigger_class_name')){
      $('.' + $(this).data('trigger_class_name')).trigger('change');
    }
  });

  $(document).on("click", ".unclickable", function(){
    return false;;
  });

  $(document).on("change", ".checkFile", function(){
    var reader = new FileReader();
    var fileField = $(this);
    var file = $(fileField)[0].files[0];

    reader.onload = function() {
      dataUrl = reader.result;
      checkFileIcon = $(fileField).closest('.checkFileParent').find('.checkFileIcon');
      $(checkFileIcon).removeClass('hide');
      var checkLink = $(checkFileIcon).find('a');
      $(checkLink).attr('download', file.name);
      $(checkLink).attr('href', dataUrl);
    }

    if (file) {
      reader.readAsDataURL(file);
    }
  });
  
  $(document).on("click", ".hard_return_back", function(){
    redirectTo(window.location.href);
  });

  $(document).on("click", ".make_request", function(){
    var url = $(this).data('request_url');
    var method_type = $(this).data('method_type');
    var t;
    clearTimeout(t);
    t = setTimeout(function () {
      $.ajax({
        url: url,
        type: method_type
      });
    }, 100);
  });

  $(document).on("change", ".media-file-upload", function(){
    var id = getId(this.id);
    var reader = new FileReader();
    var parentEl = $(this).closest('.file_attachments');
    var ele = $(parentEl).find('.preview_attachments');

    if ($(parentEl).find(".saved_media_file").length > 0){
      $(parentEl).find(".destroy-media-file").val("0");
    }
    removePreviewFileEle = "<a class='remove-file remove-media-file dib' href='javascript:void(0);'><div class='remove-preview-file remove'><i class='fa fa-times'></i></div></a>";

    preview_file_ele = "<li class='form-group'><a href='javascript:void(0);'>" + this.files.item(0).name + "</a> " + removePreviewFileEle + "</li>";
    $(ele).find("li").addClass("hide");
    $(ele).append(preview_file_ele);
  });

  $(document).on("click", ".remove-media-file", function(){
    var parentEl = $(this).closest('.file_attachments');
    $(parentEl).find(".media-file-upload").val('');
    if ($(parentEl).find(".saved_media_file").length > 0){
      $(parentEl).find(".destroy-media-file").val("1");
    }
    $(parentEl).find("li").addClass("hide");
  });

  $(document).on('click', '.checkMandatory', function(e){
    var form = $(this).closest('form');
    var mandatory_fields = $(form).find('.mandatory_field')
    var isValid = checkIfAllFieldsHaveValues(mandatory_fields);
    if (!isValid){
      e.preventDefault();
      $(mandatory_fields).each(function() {
        custom_validation(this);
      });
      $(form).find(".has-error").find('input').focus();
      $('#loader-image').hide();
    }
  });

  $(document).on("change keyup", ".mandatory_field", function(){
    custom_validation(this);
  });

  $(document).on("click", ".helpIcon", function(){
    var parentEl = $(this).closest('.helpQuestion');
    unsure_check_box = parentEl.find('input')[0];
    if(unsure_check_box.value == 'false' || unsure_check_box.value == ''){
      unsure_check_box.value = 'true';
      parentEl.addClass('activeHelp');
    }
    else{
      unsure_check_box.value = 'false';
      parentEl.removeClass('activeHelp');
    }
  });

  $(document).on("click", ".helpIconBottom", function(){  
    var parentEl = $(this).closest('.helpQuestionBottom');
    unsure_check_box = parentEl.find('input')[0];
    if(unsure_check_box.value == 'false' || unsure_check_box.value == ''){
      unsure_check_box.value = 'true';
      parentEl.addClass('activeHelp');
    }
    else{
      unsure_check_box.value = 'false';
      parentEl.removeClass('activeHelp');
    }
  });

  $(document).on('keyup', '#office_logo_text',  function() {
    var value = document.getElementById('display_office_logo_text');
    value.innerText = $("#office_logo_text").val();
    var image = document.getElementById('image_display');
    if ($(this).val() == '' && $("#office_logo_url").val() != undefined) {
      image.src = $("#office_logo_url").val();
    }else {
      image.src = $("#brand_logo_url").val();  
    }
    
  });

  // open remax email modal
  $(document).on('input propertychange paste click', '#user_email',  function() {
    userEmail = $(this).val();
    if (validateEmail(userEmail)) {
      $("#remaxEmailbtn").attr('disabled', false);
    }else{
      $("#remaxEmailbtn").attr('disabled', true);
    }
  })

  if ($("#user_email").val() != ""){
    $("#remaxEmailbtn").attr('disabled', false);
  }

  $(document).on('click', '#remaxEmailbtn',  function() {
    userVal = $('#user_email').val();
    msgText = $("#old_message").val();
    msgText = msgText.replace("userEmail", userVal);
    msgText = msgText.replace(/^\s+|\s+$/gm,'');
    $("#message").html(msgText);
  })

  // user/office contract until show/hide
  if ((".contract_until").length > 0) {
    $('.contract_type_time_limited, .contract_probation_true').click(function(){
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      $('#contract_until_' + id).show('slow');
    });

    $('.contract_probation_false').click(function(){
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      if(!$('#contract_type_time_limited_' + id).is(":checked")) {
        $('#contract_until_' + id).hide('slow');
      }
    });

    $('.contract_type_indefinitely_valid').click(function(){
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      if(!$('#contract_probation_true_' + id).is(":checked")) {
        $('#contract_until_' + id).hide('slow');
      }
    });

    var time_limited_contract_types = $(".contract_type_time_limited");
    for(var i=0; i<time_limited_contract_types.length; i++){
      var idArr = time_limited_contract_types[i].id.split("_");
      var id = idArr[idArr.length - 1];
      if ($("#contract_type_time_limited_" + id).is(":checked")){
        $('#contract_until_' + id).show('slow');
      }
      else{
        if ($("#contract_probation_true_" + id).is(":checked")){
          $('#contract_until_' + id).show('slow');
        }
        else {
          $('#contract_until_' + id).hide('slow');
        }
      }
    }
  }

  //add aditional languages
  if ($("#add_new_language").length > 0){
    var total_added_languages = 0;
    $(document).on("click", "#add_new_language",  function() {
      var count = ++total_added_languages;
      $('#input_add_language').append('<div class="added_language" id="added_language_wrapper_' + count + '"><div class="col-12 col-sm-8 col-md-10 form-group"><input type="text" id="input_add_language_'+count+'" name="languages['+ count +']" class="form-control"/></div><div class="col-12 col-sm-3 col-md-2 form-group text-right pt10"><label class="remove_language"  id="remove_language_' + count + '"><a href="javascript:void(0)" class="link-14 text-blue remove_fields dynamic"><i class="removeIcon"></i>Remove</a></label></div></div>')
    });
    $(document).on("click",".remove_language", function() {
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      $("#added_language_wrapper_"+ id).remove();
    });
  }

  //add aditional business_degrees
  if ($("#add_new_business_degree").length > 0){
    var total_added_business_degrees = 0;
    $(document).on("click", "#add_new_business_degree",  function() {
      var count = ++total_added_business_degrees;
      $('#input_add_business_degree').append('<div class="added_business_degree" id="added_business_degree_wrapper_' + count + '"><div class="col-12 col-sm-8 col-md-10 form-group"><input type="text" id="input_add_business_degree_'+count+'" name="other_business_degrees['+ count +']" class="form-control"/></div><div class="col-12 col-sm-3 col-md-2 form-group text-right pt10"><label class="remove_business_degree"  id="remove_business_degree_' + count + '"><a href="javascript:void(0)" class="link-14 text-blue remove_fields dynamic"><i class="removeIcon"></i>Remove</a></label></div></div>')
    });
    $(document).on("click",".remove_business_degree", function() {
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      $("#added_business_degree_wrapper_"+ id).remove();
    });
  }

  if ($("#steps").length > 0){
    var total_step = $("#steps").find($("input")).length/2 + 3;
    $(document).on("click", "#add_new_step",  function() {
      var count = ++total_step;
      $('#input_add_step').append('<div class="added_step" id="added_step_wrapper_' + count + '"><div class="row row7"><div class="col-12 col-sm-3 form-group pt-md-2">STEP '+ count +' </div><div class="col-12 col-sm-4 form-group"><input type="text" id="input_add_year_'+count+'" name="setting[step_'+ count +'][year]" class="form-control" placeholder = "Year"/></div><div class="col-12 col-sm-4 form-group"><input type="text" id="input_add_days_'+count+'" name="setting[step_'+ count +'][days]" class="form-control" placeholder = "days"/></div><div class="col-12 col-sm-1 pt-md-2 form-group"><a href="javascript:void(0)" class="link-14 text-blue remove_fields dynamic remove_step" id="remove_step_' + count + '"><i class="removeIcon"></i>Remove</a></div></div></div>')
    });
    $(document).on("click",".remove_step", function() {
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      $("#added_step_wrapper_"+ id).remove();
    });
  }

  if ($("#bank_account_details").length > 0){
    var total_added_bank_account = $("#bank_account_details").find($("input")).length/2;
    $(document).on("click", "#add_new_bank_account",  function() {
      var count = ++total_added_bank_account;
      $('#input_add_bank_account_details').append('<div class="added_bank_account" id="added_bank_account_wrapper_' + count + '"><div class="fieldRemove"><div class="row row7"><div class="col-12 col-sm-6 form-group"><input type="text" id="input_add_name_'+count+'" name="setting[bank_details_'+ count +'][name]" class="form-control" placeholder = "Bank name"/></div><div class="col-12 col-sm-6 form-group"><input type="text" id="input_add_bank_number_'+count+'" name="setting[bank_details_'+ count +'][number]" class="form-control" placeholder = "Bank account number" /></div></div><a href="javascript:void(0)" class="link-14 text-blue remove_fields dynamic remove_bank_account_details" id="remove_bank_account_details_' + count + '"><i class="removeIcon"></i>Remove</a></div></div>')
    });
    $(document).on("click",".remove_bank_account_details", function() {
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      $("#added_bank_account_wrapper_"+ id).remove();
    });
  }

  // check for valid hours, minutes
  $(document).on( 'change', ".time_hour",function(){
    if (!($(this).val() >= parseInt("00") && $(this).val() <= 23)){
      $(this).val("");
    }
  });

  $(document).on( 'change', ".time_minute",function(){
    if (!($(this).val() >= parseInt("00") && $(this).val() <= 59)){
      $(this).val("");
    }
  });

  $(document).ready(function () {
    setTimeout(function () {
      $(".stepBtnSticky").css('height', $('.stepBtnSticky').height());
      $(".stepBtnSticky").css('position', 'sticky');
    }, 500);  
  });
  
  $(document).ready(function () {
    setTimeout(function () {
      $(".stepBtnStickymanage").css('height', $(window).height());
      $(".stepBtnStickymanage").css('position', 'sticky');
    }, 500);
  });  
  
});

function custom_validation(element){
  var form = $(element).closest('form');
  if ($(element).val().length > 0){
    remove_validation_text(element);
  }
  else{
   $(element).parent().closest('div').addClass('has-error');
   $(element).parent().closest('div').css("position", "relative");
   $(element).parent().closest('div').find('small:eq(0)').remove();
   $(element).parent().closest('div').find('i:eq(0)').remove();
   $(element).parent().closest('div').append('<small class="help-block text-red">'+I18n.t("js.general.task.mandatory_field")+'<i class="form-control-feedback fa fa-remove" style="top: 0px; left: 5px;"></i></small>');
  }
  if ($(form).find(".has-error").length > 0){
    $(form).find("button[type='submit']").prop('disabled',true);
  }
  else{
    $(form).find("button[type='submit']").prop('disabled',false);
  }
}

function addThousandSeparators(nStr) {
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ' ' + '$2');
  }
  return x1 + x2;
}

function setScrollPosition(selectedId) {
  $('html,body').animate({scrollTop: ($("#" + selectedId).offset().top - 2) }, 'slow');
}

function slideContent(slideFrom){
  if ($("#open_card_form").val() != 'true') {
    $('#mainWrapper').show('slide', { direction : slideFrom}, 1000);
  }
}

function fadeOutFlashMessage(id){
  $("#" + id).delay(3000).fadeOut(500);
}

function getId(id){
  var idArr = id.split("_");
  return idArr[idArr.length - 1];
}

function setFlashMessage(parentId, flashId, msg, fadeOut, animate){
  $("#" + parentId).html(getFlashMessageEle(msg, flashId));
  if (animate != false){
    SetFocusOnTopOfPage()
    //$('html, body').animate({scrollTop:$('#' + parentId).position().top}, 'slow');
  }
  if (fadeOut != false) {
    fadeOutFlashMessage(flashId);
  }
}

function getFlashMessageEle(msg, flashId){
  return "<div id='" + flashId + "' class='alert alert-dismissible alert-success'><button class='close' data-dismiss='alert' type='button'>×</button>" + msg + "</div>"
}

function common_events(){
  $( '#filterFormId' ).on( 'change', 'select', function(){
    $("#loader-image").show();
    // $( '#filterFormId' ).submit();
    $( '#filter_save_button' ).click();
  });
  
  $( '#filterFormId_director' ).on( 'change', 'select', function(){
    $(".pre-icon").show();
    $( '#filterFormId_director' ).submit();
  });

  $(document).on("keyup", ".search_customer_name", function(){
    $(".pre-icon").show();
    $( '#filterFormId_director' ).submit();
  });

  $(document).on('change', '.filter_check_box', function(){
    $("#loader-image").show();
    $(this).closest('form').submit();
  });

  $('.fileUploadMedia').on( 'change', function(){
    if (this.files && this.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('.preview_file_box').html('<img src='+e.target.result+'>');
      }
      reader.readAsDataURL(this.files[0]);
    }
  });

  $("#flash_msg").delay(3000).fadeOut(500);

  $(".filter-btn-cls, #filter-btn, #saveMedia, #saveMediaNext").click(function(){
    $("#loader-image").show();
  });
  
  $(document).on("click", ".pre-icon-load", function(){
    $(".pre-icon").show();
  });

  $(document).on("click", ".pre-icon-load-remote", function(){
    $(".pre-icon").show();
  });

  $(document).on("submit", ".pre-load-form", function(){
    $(".pre-icon").show();
  });

  $(document).on("click", ".navbar-toggle", function(){
    $(this).toggleClass('active');
    $('.navbar-collapse').toggleClass('active');
    $('.menuOverlay').toggleClass('active');
  });
  $(document).on("click", ".menuOverlay", function(){
    $(this).removeClass('active');
    $('.navbar-collapse').toggleClass('active');
    $('.navbar-toggle').toggleClass('active');
  });

  $('.officeChoosePosition').change(function(){
    var idArr = this.id.split("_");
    var id = idArr[idArr.length - 2];
    var selected_option = document.getElementById("user_office_users_attributes_" + id + "_position");
    var position_val = selected_option[selected_option.selectedIndex].value;
    if(position_val=="INDIVIDUAL" || position_val=="NO_POSITION"){
      $("#user_office_users_attributes_"+ id +"_team_id").addClass('hide');
      $("#user_office_users_attributes_"+ id +"_team_id").val("");
    }
    else{
      $("#user_office_users_attributes_"+ id +"_team_id").removeClass('hide');
    }
  });

  $(document).on('click', '.hideShowModal', function(){
    $('.modal').modal('hide');
    var show_modal_id = $(this).data('show_modal_id');
    if ($('#' + show_modal_id).length > 0){
      setTimeout(function () {
        $("#" + show_modal_id).modal('show');
      }, 1000);
    }
  });

  $(document).on('change', '.media_file', function(e){
    var parentEl = $(this).closest('.parent');
    $.each(this.files, function() {
      showMediaFilename(this, parentEl);
    });
  });
}

function showMediaFilename(file, parentEl){
  var reader = new FileReader();
  reader.onload = function(e) {
    $(parentEl).find('.media_attachment_name').val(file.name);
  }
  reader.readAsDataURL(file);
}

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function showFilename(file, ele) {
  var reader = new FileReader();
  reader.onload = function(e) {
    $(ele).val(file.name);
  }
  reader.readAsDataURL(file);
}

function redirectTo(url, params){
  $.each(params, function(index, value){
    url = url + value;
  });
  window.location.replace(url);
}

function checkIfAllFieldsHaveValues(elements) {
  var isValid = true;
  $(elements).each(function() {
    if ( !$(this).prop('disabled') && !$(this).prop('readonly') && $(this).val() === '' )
      isValid = false;
  });
  return isValid;
}

function SetFocusOnTopOfPage(){
  $(".modal").animate({ scrollTop: 0 }, "slow");
}

function isInArray(value, array) {
  return array.indexOf(value) > -1;
}

function checkIfAllFieldsHaveUniqueValues(elements) {
  var isValid;
  var values = [];
  $(elements).each(function() {
    if ( $(this).val() != '' ) {
      values.push($(this).val())
      var check_valid = true;
      for (i=0; i<values.length; i++) {
        for (j=i+1; j<values.length; j++) {
          if (values[i]==values[j]) {
            isValid = false;
            var first_ele = $(elements)[i];
            var second_ele = $(elements)[j];
            custom_uniquness_validation(first_ele, second_ele);
            check_valid = false;
            break;
          }
        }
      }
      if (check_valid){
        var class_name = $(this).attr("data-unique");
        $('.'+class_name).parent().closest('div').removeClass('has-error');
        $('.'+class_name).parent().closest('div').find('small:eq(0)').remove();
        $('.'+class_name).parent().closest('div').find('i:eq(0)').remove();
      }
    }
  });
  return isValid;
}

function isAnyModalOpen() {
  return $('.modal.in').length > 0;
}

function display_validation_text(element,msg){
  $(element).parent().closest('div').addClass('has-error');
  $(element).parent().closest('div').find('small:eq(0)').remove();
  $(element).parent().closest('div').find('i:eq(0)').remove();
  $(element).parent().closest('div').append('<i class="form-control-feedback fa fa-remove" style="top: 0px;"></i>'); 
  $(element).parent().closest('div').append('<small class="help-block">'+msg+'</small>');
}
function remove_validation_text(element){
  $(element).parent().closest('div').removeClass('has-error');
  $(element).parent().closest('div').find('small:eq(0)').remove();
  $(element).parent().closest('div').find('i:eq(0)').remove();
}

function checkIfSumEqualToOne(elements){
  var isValid = true;
  var total_fraction = 0;
  $(elements).each(function() {
    var fraction = $(this).val();
    if (fraction.match('/') != null){
      var split = fraction.split('/');
      var result = parseInt(split[0], 10) / parseInt(split[1], 10);
      total_fraction += parseFloat(result)
    }else{
      total_fraction += (parseFloat(fraction))
    }
  });
  if (total_fraction == 1) {
    isValid = true;
  }
  else{
    isValid = false; 
  }
  return isValid;
}

function checkIfPerSumEqualToOne(elements){
  var isValid = true;
  var total_percentage = 0;
  $(elements).each(function() {
    if ( $(this).val() != '' ) {
      total_percentage += parseFloat($(this).val())
    }
  });
  if (total_percentage == 100) {
    isValid = true;
  }
  else{
    isValid = false; 
  }
  return isValid;
}

function checkIsNaNVal(val){
  val = (isNaN(val)) ? 0 : val;
  return val;
}

function checkNumericValue(number, decimal_points = 2) {
  if (number){
    return parseFloat(parseFloat(number.toString().replace(' ', '').replace(",", ".")).toFixed(decimal_points));
  }
  else{
    return 0;
  }
}

function show_formatted_number(number, decimal_points = 2, with_space = true){
  if (number){
    number = checkNumericValue(number);
    var parts = number.toString().split(".");
    if (with_space){
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return parts.join(",");
  }
  else{
    0
  }
}

function commonAddOrRemoveHideClass(check_object, match_value, type){
  var parentEl = $(check_object).closest(".parent_hide_show_class");
  var childEl = $(parentEl).find(".child_hide_show_class");
  if ($(check_object).val() == match_value){
    if (type == "hide_fields"){
      $(childEl).removeClass("hide");
    }
    if (type == "disable_fields"){
      $(childEl).find('input').attr("disabled", false);
    }
  }
  else{
    if (type == "hide_fields"){
      $(childEl).addClass("hide");
    }
    if (type == "disable_fields"){
      $(childEl).find('input').attr("disabled", true);
      $(childEl).find('input').val("");
    }
  }
}

function modify_next_elements(){
  var elements = $(".jscroll-inner")
  $(elements).each(function() {
    var added = $(this).find('.jscroll-added')
    $(this).append($(added).children('.col-12'))
    $(added).children('.col-12').remove()
    $(this).append($(added))
  });
}