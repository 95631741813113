$(document).ready(function(){
  $(document).on("click", ".signature", function(){
    $(".hide_agreement_modal").click();
    var object_id = $(this).data("object_id");
    if (object_id){
      data = {
              'object_type': $(this).data("object_type"),
              'signature_after_close_modal': $(this).data("signature_after_close_modal"),
              'signature_type': $(this).data("signature_type")
              }
      var associate_type = $(this).data("associate_type");
      var associate_id = $(this).data("associate_id");
      if (associate_type && associate_id){
        data['associate_type'] = associate_type;
        data['associate_id'] = associate_id;
      }
      $.ajax({
        url: '/assignments/'+object_id+'/fetch_signature',
        type: 'POST',
        data: data
      });
    }
  });

  $(document).on("click", "#signature_modal_close", function(){
    $("#signatureModal").modal("hide");
    setTimeout(function(){
      $("#" + $("#signature_after_close_modal").val()).modal("show");
    }, 500);
  });
});