$(document).ready(function(){

  $(document).on("change keyup", ".link_search_filter , #link_sort_filter", function(){
    var service_name = $(".link_search_filter").val();
    var service_filter = $("#link_sort_filter").val();
    $.ajax({
      type:'GET',
      url:'/link_library/link_search_filter',
      data: { service_name : service_name, service_filter : service_filter},
      success:function(){
      }
    });
  });

  $(document).on("change", ".check_has_id_pass", function(){
    var has_pid_pass = $("input:radio.check_has_id_pass:checked").val();
     if (has_pid_pass == "true") {
      $("#check_has_id_pass").removeClass("hide")
     } else {
      $("#check_has_id_pass").addClass("hide")
     }

  });

  $(document).on("click", ".hide_username_button", function(){
      $("#link_library_username").attr('type', 'password');
      $(".hide_username_button").addClass("hide")
      $(".show_username_button").removeClass("hide")

  });
  $(document).on("click", ".show_username_button", function(){
      $("#link_library_username").attr('type', 'text');
      $(".show_username_button").addClass("hide")
      $(".hide_username_button").removeClass("hide")
  });

  $(document).on("click", ".hide_password_button", function(){
      $("#link_library_password").attr('type', 'password');
      $(".hide_password_button").addClass("hide")
      $(".show_password_button").removeClass("hide")

  });
  $(document).on("click", ".show_password_button", function(){
      $("#link_library_password").attr('type', 'text');
      $(".show_password_button").addClass("hide")
      $(".hide_password_button").removeClass("hide")
  });

  $(document).on("click", ".hide_username", function(){
      var check_username = $(this).closest('.check_username');
      var username = $(check_username).find('.username');
      var show_username = $(check_username).find('.show_username');
      $(username).attr('type', 'password');
      $(this).addClass("hide");
      $(show_username).removeClass("hide");
  });

  $(document).on("click", ".show_username", function(){
      var check_username = $(this).closest('.check_username');
      var username = $(check_username).find('.username');
      var hide_username = $(check_username).find('.hide_username');
      $(username).attr('type', 'text');
      $(this).addClass("hide");
      $(hide_username).removeClass("hide");
  });

  $(document).on("click", ".hide_password", function(){
      var check_password = $(this).closest('.check_password');
      var password = $(check_password).find('.password');
      var show_password = $(check_password).find('.show_password');
      $(password).attr('type', 'password');
      $(this).addClass("hide");
      $(show_password).removeClass("hide");
  });

  $(document).on("click", ".show_password", function(){
      var check_password = $(this).closest('.check_password');
      var password = $(check_password).find('.password');
      var hide_password = $(check_password).find('.hide_password');
      $(password).attr('type', 'text');
      $(this).addClass("hide");
      $(hide_password).removeClass("hide");
  });


  
  
});

function getId(id){
  var idArr = id.split("_");
  return idArr[idArr.length - 1];
}