I18n.translations || (I18n.translations = {});

I18n.translations["en"] = {"js":{"general":
                                  {
                                    'm2': 'm2',
                                    "task": 
                                    { 
                                      "provide_signature" : "Please provide signature first.",
                                      "remove": "Remove",
                                      "mandatory_field" : "Mandatory field",
                                      "please_select_minimum_photographs": "Please select minimum photographs to proceed",
                                      "delete_content" : "Are you sure you want to remove this record permanently from database?",
                                      "are_you_sure": "Are you sure?",
                                      "cannot_be_same": "Can't be same",
                                      "must_be_less_or_equal_to_1": "must be less than or equal to 1",
                                      "must_be_less_or_equal_to_100": "must be less than or equal to 100",
                                      "total_must_be_less_or_equal_to_1": "total fraction ownership should be equal to 1",
                                      "total_must_be_less_or_equal_to_100": "total % ownership should be equal to 100",
                                      "total_per_must_be_less_or_equal_to_100": "total % should be equal to 100",
                                      "select_at_least_one_customer": "Please select atleast one customer",
                                      "good": "GOOD",
                                      "bad": "BAD",
                                      "satisfactory": "SATISFACTORY",
                                      "property_fill_placeholder": "Fill province, city, suburb, postal code",
                                      "write_desired_street_address": "Write here desired street address",
                                      "visit_report": "Visit report",
                                      "year": "Year",
                                      "confirm_cost": "Please confirm the cost first.",
                                      "alarm_date_must_be_greater_or_equal_to_end_date": "The alarm date must be at a time prior to the end date of the agreement.",
                                      "please_check_less_than_three_customer": "You can choose three customers only."
                                    },
                                  }
                                }
                          };

I18n.translations["fi"] = {"js":{"general":
                                  {
                                    'm2': 'm2',
                                    "task": 
                                    { 
                                      "provide_signature" : "Anna allekirjoitus ensin.",
                                      "remove": "Poista",
                                      "mandatory_field" : "Pakollinen tieto",
                                      "please_select_minimum_photographs": "Valitse vähintään valokuvat, jotta voit jatkaa",
                                      "delete_content" : "Haluatko varmasti poistaa tämän tietueen pysyvästi tietokannasta?",
                                      "are_you_sure": "Oletko varma?",
                                      "cannot_be_same": "Ei voi olla sama",
                                      "must_be_less_or_equal_to_1": "on oltava pienempi tai yhtä suuri kuin 1",
                                      "must_be_less_or_equal_to_100": "on oltava pienempi tai yhtä suuri kuin 100",
                                      "select_at_least_one_customer": "Valitse ainakin yksi asiakas",
                                      "good": "HYVÄ",
                                      "bad": "HUONO",
                                      "satisfactory": "TYYDYTTÄVÄ",
                                      "property_fill_placeholder": "Täytä maakunta, kaupunki, lähiö, postinumero",
                                      "write_desired_street_address": "Kirjoita tähän haluttu katuosoite",
                                      "visit_report": "Käy raportissa",
                                      "year": "Vuosi",
                                      "confirm_cost": "Vahvista ensin kustannukset.",
                                      "alarm_date_must_be_greater_or_equal_to_end_date": "Hälytyspäivän on oltava aikaisemmin ennen sopimuksen päättymispäivää",
                                      "please_check_less_than_three_customer": "Voit valita vain kolme asiakasta."
                                    },
                                  }
                                }
                           };