$(document).ready(function(){
  var t;

  $(document).on('keyup', '#search, .search',  function() {
    clearTimeout(t);
    t = setTimeout(function () {
      $('#filterFormId').submit();
      $("#loader-image").show();
    }, 500);
  });

  //obstacle_check
  $(document).on('click', '#obstacle_check_search', function() {
    var id = $('#assignment_id').val();
    var url = "/assignments/obstacle_check"
    var http_method = "GET"
    var data = { id : id, main_customer_first_name : $("#main_customer_first_name").val(), main_customer_last_name : $("#main_customer_last_name").val(), counterparty_first_name : $("#counterparty_first_name").val(), counterparty_last_name : $("#counterparty_last_name").val(), counterparty_address : $("#counterparty_address").val(), counterparty_post_number : $("#counterparty_post_number").val(), counterparty_city : $("#counterparty_city").val(), counterparty_phone_number : $("#counterparty_phone_number").val(), counterparty_email : $("#counterparty_email").val(), counterparty_person_id : $("#counterparty_person_id").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#messageSearch',  function() {
    var url = "/messages/search/user_filter"
    var http_method = "GET"
    var data = { search : $("#messageSearch").val(), id : $("#id").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#customer_messageSearch',  function() {
    var url = "/messages/search/customer_filter"
    var http_method = "GET"
    var data = { search : $("#customer_messageSearch").val(), id : $("#id").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#speciality_search',  function() {
    var url = "/specialities/search/speciality_filter"
    var http_method = "GET"
    var data = { search : $("#speciality_search").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#action_search',  function() {
     var id = $('#customer_invoice_id').val();
    var url = "/billers/search/action_filter"
    var http_method = "GET"
    var data = { id : id, search : $("#action_search").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#expense_search',  function() {
     var id = $('#customer_invoice_id').val();
    var url = "/billers/search/expense_filter"
    var http_method = "GET"
    var data = { id : id, search : $("#expense_search").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#product_search',  function() {
    var id = $('#customer_invoice_id').val();
    var url = "/billers/search/product_filter"
    var http_method = "GET"
    var data = { id : id, search : $("#product_search").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#executive_search, #executive_speciality_search, #executive_language_skill_search, #executive_job_role_search, #executive_office_type_search',  function() {
    var url = "/executives/search/user_filter"
    var http_method = "GET"
    var data = { search : $("#executive_search").val(), speciality : $("#executive_speciality_search option:selected").val(), language_skill : $("#executive_language_skill_search option:selected").val(), job_role : $("#executive_job_role_search option:selected").val(), office_type : $("#executive_office_type_search option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#director_search, #director_speciality_search, #director_language_skill_search, #director_job_role_search, #director_office_type_search',  function() {
    var url = "/directors/search/user_filter"
    var http_method = "GET"
    var data = { search : $("#director_search").val(), speciality : $("#director_speciality_search option:selected").val(), language_skill : $("#director_language_skill_search option:selected").val(), job_role : $("#director_job_role_search option:selected").val(), office_type : $("#director_office_type_search option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#expert_search, #expert_speciality_search, #expert_language_skill_search, #expert_job_role_search, #expert_office_type_search',  function() {
    var url = "/experts/search/user_filter"
    var http_method = "GET"
    var data = { search : $("#expert_search").val(), speciality : $("#expert_speciality_search option:selected").val(), language_skill : $("#expert_language_skill_search option:selected").val(), job_role : $("#expert_job_role_search option:selected").val(), office_type : $("#expert_office_type_search option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#executive_speciality_search, #executive_speciality_role',  function() {
    var id = $('#user_id').val();
    var url = "/executives/search/speciality_filter"
    var http_method = "GET"
    var data = { id : id, search : $("#executive_speciality_search").val(), job_role : $("#executive_speciality_role option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#director_speciality_search, #director_speciality_role',  function() {
    var id = $('#user_id').val();
    var url = "/directors/search/speciality_filter"
    var http_method = "GET"
    var data = { id : id, search : $("#director_speciality_search").val(), job_role : $("#director_speciality_role option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#expert_speciality_search, #expert_speciality_role',  function() {
    var id = $('#user_id').val();
    var url = "/experts/search/speciality_filter"
    var http_method = "GET"
    var data = { id : id, search : $("#expert_speciality_search").val(), job_role : $("#expert_speciality_role option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#person_search, #person_status, #price_list_status',  function() {
    var url = "/price_list/search/user_filter"
    var http_method = "GET"
    var data = { search : $("#person_search").val(), person_status : $("#person_status option:selected").val(), price_list_status : $("#price_list_status option:selected").val(), office_id : $("#office_id").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#message_search',  function() {
    var url = "/messages/search/filter"
    var http_method = "GET"
    var data = { search : $("#message_search").val(), id : $("#id").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on("change", ".select_interal_steps", function(){
    var steps = $(this).val();
    if(steps == "TWO_STEP"){
      var interval_steps = 2
    } else if (steps == "FOUR_STEP") {
      var interval_steps = 4
    } else {
      var interval_steps = 6
    }
    var url = "/price_list/set_interval_steps"
    var http_method = "GET"
    var data = { interval_steps : interval_steps }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#assignment_speciality_search',  function() {
    var id = $('#assignment_id').val();
    var url = "/assignments/search/assignment_category_filter"
    var http_method = "GET"
    var data = { id : id, search : $("#assignment_speciality_search").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#team_member_search, #team_member_speciality_search, #team_member_language_skill_search, #team_member_job_role_search, #team_member_office_type_search',  function() {
    var id = $('#assignment_id').val();
    var url = "/assignments/search/assignment_team_member_filter"
    var http_method = "GET"
    var data = { id : id, search : $("#team_member_search").val(), speciality : $("#team_member_speciality_search option:selected").val(), language_skill : $("#team_member_language_skill_search option:selected").val(), job_role : $("#team_member_job_role_search option:selected").val(), office_type : $("#team_member_office_type_search option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#billerCustomerSearch, #customerTypeFilter',  function() {
    var url = "/billers/search/customer_filter/"
    var http_method = "GET"
    var data = { id : $("#customer_id").val(), search: $("#billerCustomerSearch").val(), customer_type: $("#customerTypeFilter option:selected").val()}
    prepareAjaxCall(url, http_method, data);
  });  

  $(document).on('keyup', '#customerSearch',  function() {
    var assignment_id = $("#assignment_id").val();
    var url = "/search/filter/"
    var http_method = "GET"
    var data = { search: $("#customerSearch").val(), search_type: 'assignment_customers', filter_id: 'filter_customers', show_scroll_bar: true, filter: true }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#assignmentSearch',  function() {
    var url = "/search/filter/"
    var http_method = "GET"
    var data = { search: $("#assignmentSearch").val(), search_type: 'assignments', filter_id: 'filter_assignments', show_scroll_bar: true, filter: true }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('click', '.assignment_customer_filter_save_btn', function(){
    var parentEl = $(this).closest('.filter_parent_form');
    var url = "/assignments/search/assignment_customer_filter"
    var http_method = "GET"
    var data = { assignment_customer_filter: true, name_search: $(parentEl).find(".filter_by_customer_name").val(), assignment_name_search: $(parentEl).find('.filter_by_assignment_name').val(), filter: true }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '.search_customer_list_by_name, .register_filter, #start_date, #end_date', function(){
    var parentEl = $(this).closest('.filter_parent_form');
    var user_id = $("#selected_user_id").val();
    var url = "/assignments/search/customer_filter"
    var http_method = "GET"
    var data = { user_id: user_id, name_search: $(".search_customer_list_by_name").val(), register_search: $('#choose_register option:selected').val(), start_date_search: $('#start_date').val(), end_date_search: $('#end_date').val(), filter: true }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on("click", ".object_check", function(){
    checked = $("input[type=checkbox]:checked").length;
    if ($(this).hasClass("customer_check")) {
      if (checked >= 4) {
        alert("Please check Less than or equal to three checkbox.");
        return false;
      }
    }
    var parentEl = $(this).closest('.parent');
    var object_id = $(this).data("object_id");
    var filter_objects_ele = $(this).closest('.filter_objects');
    var final_selected_objects_ele = $(filter_objects_ele).find('.final_selected_objects');
    var is_checked = $(this).is(":checked");
    // if only one check box is allowed to be selected
    if ($(this).hasClass('single_check')){
      $(final_selected_objects_ele).val('');
      $(filter_objects_ele).find('.object_check').prop('checked', false);
      $(filter_objects_ele).find('.select_text').removeClass('hide');
      $(filter_objects_ele).find('.unselect_text').addClass('hide');
    }
    //if checked
    if (is_checked) {
      // update to unselect text
      $(this).prop('checked', true);
      addToFinalSelectedObjects(final_selected_objects_ele, object_id, parentEl);
      $(parentEl).find(".customer_check").addClass("darkGrayBgBox");
      $(parentEl).find(".customer_check").removeClass("whiteBg");
      removeDisableAttachmentButton();
    }
    else {
      // update to select text
      $(this).prop('checked', false);
      removeFromFinalSelectedObjects(final_selected_objects_ele, object_id, parentEl);
      $(parentEl).find(".customer_check").removeClass("darkGrayBgBox");
      $(parentEl).find(".customer_check").addClass("whiteBg");
      removeDisableAttachmentButton();
    }
  });

  

  $(document).on('keyup', '.filter_by_attorney_name', function(){
    var parentEl = $(this).closest('.filter_parent_form');
    var url = "/assignments/search/attorney_filter"
    var http_method = "GET"
    var data = { attorney_search: $(parentEl).find(".filter_by_attorney_name").val(), show_in_modal: $(parentEl).find(".show_in_modal").val(), filter: true }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('change', '#all_billables_filter, #all_experts_filter, #oldest_first_filter',  function() {
    var url = "/billers/search/item_filter"
    var http_method = "GET"
    var data = { id : $("#customer_invoice_id").val(), all_billables : $("#all_billables_filter option:selected").val(), all_experts : $("#all_experts_filter option:selected").val(), oldest_first : $("#oldest_first_filter option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup, click', '#contact_search_name, #contact_customer_type, #contact_status_type, #contact_year, #contact_month',  function() {
    var contact_type = $("#contact_type").val();
    var parentEl = $(this).parent().parent().parent();
    var type = $(parentEl).find(".listing_type").attr('id');
    var listing_type = getId(type);
    var url = "/assignments/search/contact_filter"
    var http_method = "GET"
    var data = { contact_type : contact_type, listing_type : listing_type, search : $('#contact_search_name').val(), customer_type : $(parentEl).find("#contact_customer_type option:selected").val(), status_type : $("#contact_status_type option:selected").val(), contact_year : $("#contact_year option:selected").val(), contact_month : $("#contact_month option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#contact_receiver_search, #contact_receiver_speciality_search, #contact_receiver_language_skill_search, #contact_receiver_job_role_search, #contact_receiver_office_type_search',  function() {
    var customer_reassigner_id = $("#customer_reassigner_id").val();
    var url = "/customer_reassigners/search/contact_receiver_filter"
    var http_method = "GET"
    var data = { id : customer_reassigner_id, search : $("#contact_receiver_search").val(), speciality : $("#contact_receiver_speciality_search option:selected").val(), language_skill : $("#contact_receiver_language_skill_search option:selected").val(), job_role : $("#contact_receiver_job_role_search option:selected").val(), office_type : $("#contact_receiver_office_type_search option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup, click', '#call_back_search, #call_back_type, #call_back_year, #call_back_month',  function() {
    var call_request_type = $("#call_request_type").val();
    var url = "/assignments/search/call_request_filter"
    var http_method = "GET"
    var data = { call_request_type : call_request_type, call_back_search : $("#call_back_search").val(), call_back_type : $("#call_back_type option:selected").val(), call_back_year : $("#call_back_year option:selected").val(), call_back_month : $("#call_back_month option:selected").val() }
    prepareAjaxCall(url, http_method, data);
  });

  $(document).on('keyup', '#documentSearch', '#folder_phase', function(){
    var parentEl = $(this).closest('.filter_assignment_document');
    var id = $(parentEl).find("#assignment_id").val();
    var folder_phase = $(parentEl).find("#folder_phase").val();
    var url = "/assignments/search/document_group_filter"
    var http_method = "GET"
    var data = { id : id, document_search: $(parentEl).find("#documentSearch").val(), folder_phase: $(parentEl).find('#folder_phase').val()}
    prepareAjaxCall(url, http_method, data);
  });
});

function prepareAjaxCall(url, http_method, data){
  clearTimeout(t);
  var t = setTimeout(function () {
    $.ajax({
      url: url,
      type: http_method,
      data: data
    });
  }, 500);
}

function removeDisableAttachmentButton(){
  if ($("input:checkbox:checked").length > 0){
    $('.checkbox_count').removeClass("disableattchmentbutton");
  } 
  else{
    $('.checkbox_count').addClass("disableattchmentbutton");
  }
}

function checkUncheckObjects(search_type, filter_id){
  var filter_objects_ele = $("#"+filter_id).closest('.filter_objects');
  var final_selected_objects_ele = $(filter_objects_ele).find('.final_selected_objects');
  if ($(final_selected_objects_ele).length > 0){
    var final_selected_objects = $(final_selected_objects_ele).val().split(',');
    $(filter_objects_ele).find('.object_check').prop('checked', false);
    $(filter_objects_ele).find('.select_text').removeClass('hide');
    $(filter_objects_ele).find('.unselect_text').addClass('hide');

    final_selected_objects.forEach(function(object_id){
      var object_ele = $(filter_objects_ele).find('*[data-object_id="'+object_id+'"]');
      if ($(object_ele).length > 0){
        $(object_ele).prop('checked', true);
        var parentEl = $(object_ele).closest('.parent');
        $(parentEl).find('.select_text').addClass('hide');
        $(parentEl).find('.unselect_text').removeClass('hide');
      }
    });
  }
}

// remove an id from holder and update it
function removeFromFinalSelectedObjects(final_selected_objects_ele, object_id, parentEl) {
  var object_id = (object_id).toString()
  var idArr = $(final_selected_objects_ele).val();
  idArr = idArr ? idArr.split(",") : [];
  var index = idArr.indexOf(object_id);
  if (index > -1) {
    idArr.splice(index, 1);
    $(final_selected_objects_ele).val(idArr.join(","));
  }
  set_select_text($(parentEl).find('.select_text'), $(parentEl).find('.unselect_text'));
}

// add an id to holder and update it
function addToFinalSelectedObjects(final_selected_objects_ele, id, parentEl) {
  var idArr = $(final_selected_objects_ele).val();
  idArr = idArr ? idArr.split(",") : [];
  var index = idArr.indexOf(id);
  if (index < 0) {
    idArr.push(id);
    $(final_selected_objects_ele).val(idArr.join(","));
  }
  set_unselect_text($(parentEl).find('.select_text'), $(parentEl).find('.unselect_text'));
}

//show unselect text, hide select text
function set_unselect_text(selectId, unselectId) {
  $(selectId).addClass('hide');
  $(unselectId).removeClass('hide');
}

//show select text, hide unselect text
function set_select_text(selectId, unselectId) {
  $(selectId).removeClass('hide');
  $(unselectId).addClass('hide');
}

function getId(id){
  var idArr = id.split("_");
  return idArr[idArr.length - 1];
}