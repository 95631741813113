$(document).ready(function(){

	$(document).on("click", "#confirm_record_billing", function(){
    $('.record_billing').addClass('active');
    $('.total_billing').removeClass('active');
    $( "#record_billing" ).removeClass( "hide");
    $( "#total_billing" ).addClass( "hide" );
    $( ".record_billing_title" ).removeClass( "hide" );
    $( ".total_billing_title" ).addClass( "hide" );
  });

  $(document).on("click", "#confirm_total_billing", function(){
    $('.record_billing').removeClass('active');
    $('.total_billing').addClass('active');
    $( "#record_billing" ).addClass( "hide" );
    $( "#total_billing" ).removeClass( "hide" );
    $( ".record_billing_title" ).addClass( "hide" );
    $( ".total_billing_title" ).removeClass( "hide" );
  });

	$(document).on("click", ".confirm_responsible_attorney", function(){
		var id = getId(this.id);
		if ($(this).is(":checked")){
			$(".confirm_responsible_attorney").prop('checked', false);
			$(this).prop('checked', true);
			$('.select_text').removeClass('hide');
			$('.unselect_text').addClass('hide');
			var select_ele = $("#responsible_attorney_select_text_" + id);
			var unselect_ele = $("#responsible_attorney_unselect_text_" + id);
			set_unselect_text(select_ele, unselect_ele);
		} else {
			set_select_text(select_ele, unselect_ele);
		}
	});

	$(document).on("keyup", ".forward_call_request_details", function(){
		var forward_call_request_details = $("#call_request_email").val();
		if(forward_call_request_details <= 0)
		{
			$("#addFromPersonalRegister").addClass('disableattchmentbutton');
		}
		else
		{
			$("#addFromPersonalRegister").removeClass('disableattchmentbutton');
		}
	});

	$(document).on("click", ".show_detail_drop_down_menu", function(){
		var parentEl = $(this).parent().closest('.drop_down_data_list');
		$(parentEl).find('.set_title_name').toggleClass("hide");
		$(parentEl).find('.show_upside_arrow').toggleClass("hide");
		$(parentEl).find('.show_drop_down_data').toggleClass("hide");
	});

	$(document).on("keyup", ".set_assignment_justification", function(){
    var assignment_justification = $(this).val();
    if(assignment_justification.length >= 10)
    {
			$(".save_justification").removeClass('disableattchmentbutton');
    }
    else
    {
    	$(".save_justification").addClass('disableattchmentbutton');
    }
	});

	$(document).on("click", ".assignment_team_member_confirm", function(){
		var id = getId(this.id);
		if ($(this).is(":checked")){
			var parent_ele = $(this).closest('.assignment_team_member');
			var select_ele = $(parent_ele).find("#assignment_team_select_text_" + id);
			var unselect_ele = $(parent_ele).find("#assignment_team_unselect_text_" + id);
			set_unselect_text(select_ele, unselect_ele);
		} else {
			set_select_text(select_ele, unselect_ele);
		}
	});

	$(document).on("click", ".confirm_counterparty", function(){
		$("#counterparty_info").val(true);
		$(".confirm_counterparty").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_counterparty").removeClass('btn-red').addClass('btn-gray');
		$(".show_counterparty_information").removeClass('hide');
		$("#counterparty_first_name").addClass('mandatory_field');
		$("#counterparty_last_name").addClass('mandatory_field');
		$("#counterparty_email").addClass('mandatory_field');
	});

	$(document).on("click", ".unconfirm_counterparty", function(){
		$("#counterparty_info").val(false);
		$(".confirm_counterparty").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_counterparty").addClass('btn-red').removeClass('btn-gray');
		$(".show_counterparty_information").addClass('hide');
		$("#counterparty_first_name").removeClass('mandatory_field');
		$("#counterparty_last_name").removeClass('mandatory_field');
		$("#counterparty_email").removeClass('mandatory_field');
	});

	$(document).on("click", ".confirm_legal_assistant", function(){
		$("#check_legal_assistant").val(true);
		$(".confirm_legal_assistant").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_legal_assistant").removeClass('btn-red').addClass('btn-gray');
		$(".show_legal_assitant_info").removeClass('hide');
	});

	$(document).on("click", ".unconfirm_legal_assistant", function(){
		$("#check_legal_assistant").val(false);
		$(".confirm_legal_assistant").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_legal_assistant").addClass('btn-red').removeClass('btn-gray');
		$(".show_legal_assitant_info").addClass('hide');
	});

	$(document).on("click", "#addCustomerSubmit", function(){
		if ($("#final_customers").val() != ''){
			$("#loader-image").show();
			$("#selectCustomerAssignmentformId").submit();
		}else{
			setFlashMessage("selectCustomerError", "selectCustomerErrorFlash", I18n.t("js.general.task.select_at_least_one_customer"));
		}
	});

	$(document).on("click", ".confirm_counterparty", function(){
		$("#legal_insurance").val(true);
		$(".confirm_legal_insurance").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_legal_insurance").removeClass('btn-red').addClass('btn-gray');
		$(".show_insurance_information").removeClass('hide');
	});

	$(document).on("click", ".unconfirm_counterparty", function(){
		$("#legal_insurance").val(false);
		$(".confirm_legal_insurance").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_legal_insurance").addClass('btn-red').removeClass('btn-gray');
		$(".show_insurance_information").addClass('hide');
	});

	$(document).on("click", ".assigned_document_group_save", function(){
		var form = $(this).closest('.document_group_form');
		var authenticity_token = $(form).find('#authenticity_token').val();
		var name = $(form).find('#document_group_name').val();
		var document_group_id = $(form).find('#document_group_id').val();
		var assignment_id = $(form).find('#id').val();
		var obj = {};
		obj["name"] = name;

		$.ajax({
			type:'PUT',
			url:'/assignments/' + assignment_id + '/update_document_group/' + document_group_id,
			data: { authenticity_token : authenticity_token, id : assignment_id, document_group_id : document_group_id, document_group : obj },
			success:function(){
			}
		});
	});

	$(document).on("click", ".assigned_document_save", function(){
		var id = getId(this.id);
		var form = $(this).closest('.assigned_document_form');
		var authenticity_token = $(form).find('#authenticity_token').val();
		var document_group_id = $(form).find('#document_group_id').val();
		var name = $(form).find('.document_name').val();
		var file_date = $(form).find('.file_date').val();
		var assignment_id = $(form).find('#id').val();
		var document_id = $(form).find('#document_id').val();
		var creator_id = $(form).find('#creator_id').val();
		var updater_id = $(form).find('#updater_id').val();
		var formData = new FormData();
		var images = $(form).find('.groupDocumentMedia')[0].files
		
		var obj = {};
		obj["name"] = name;
		obj["file_date"] = file_date;
		obj["creator_id"] = creator_id;
		obj["updater_id"] = updater_id;
		obj = JSON.stringify(obj);
		formData.append('attachment',images[0]);
		formData.append('authenticity_token',authenticity_token);
		formData.append('id',assignment_id);
		formData.append('document_group_id',document_group_id);
		formData.append('document',obj);
		formData.append('document_id',document_id);

		$.ajax({
			type:'PUT',
			url:'/assignments/' + assignment_id + '/update_document/' + document_id,
			processData: false,
			contentType: false,
			data: formData,
			success:function(){
			}
		});
	});

	$(document).on("click", ".assign_document_group_create", function(){
		var form = $(this).closest('.assigned_contract_group_form');
		var authenticity_token = $(form).find('#authenticity_token').val();
		var element_id = $(form).find('#element_id').val();
		var name = $(form).find('#document_group_name').val();
		var document_type = $(form).find('#document_type').val();
		var folder_phase = $(form).find('#folder_phase').val();
		var assignment_id = $(form).find('#id').val();
		var obj = {};
		obj["name"] = name;
		obj["assignment_id"] = assignment_id;
		obj["document_type"] = document_type;
		obj["folder_phase"] = folder_phase;

		$.ajax({
			type:'POST',
			url:'/assignments/' + assignment_id + '/create_document_group',
			data: { authenticity_token : authenticity_token, element_id : element_id, id : assignment_id, document_group : obj },
			success:function(){
			}
		});
	});

	$(document).on("click", ".assign_document_create", function(e){
		var form = $(this).closest('.document_parent');
		var authenticity_token = $(form).find('#authenticity_token').val();
		var element_id = $(form).find('#element_id').val();
		var document_group_id = $(form).find('#document_group_id').val();
		var name = $(form).find('.document_name').val();
		var file_date = $(form).find('.file_date').val();
		var assignment_id = $(form).find('#id').val();
		var creator_id = $(form).find('#creator_id').val();
		var updater_id = $(form).find('#updater_id').val();
		var formData = new FormData();
		var images = $(form).find('.groupDocumentMedia')[0].files

		var obj = {};
		obj["name"] = name;
		obj["file_date"] = file_date;
		obj["creator_id"] = creator_id;
		obj["updater_id"] = updater_id;
		obj = JSON.stringify(obj);
		formData.append('attachment',images[0]);
		formData.append('authenticity_token',authenticity_token);
		formData.append('element_id',element_id);
		formData.append('id',assignment_id);
		formData.append('document_group_id',document_group_id);
		formData.append('document',obj);

		$.ajax({
			type:'POST',
			url:'/assignments/'+ assignment_id + '/create_document',
			processData: false,
			contentType: false,
			data: formData,
			success:function(){
			}
		});
	});

	//show assignement document group form on clicking edit group btn
	$(document).on("click", ".assigned_document_group_edit", function(){
		var id = getId(this.id);
		$("#assigned_document_group_form_" + id).removeClass('hide');
	});

	//hide assignement document group form on clicking cancel btn
	$(document).on("click", ".assigned_document_group_cancel", function(){
		var id = getId(this.id);
		$("#assigned_document_group_lbl_" + id).removeClass('hide');
		$("#assigned_document_group_form_" + id).addClass('hide');
	});

	//remove the create assignement document group/assignement document form when clicked on remove btn
	$(document).on("click", ".assignment_document_group_remove", function(){
		$("#" + getId(this.id)).remove();
	});

	$(document).on('click', '.assignment_document_remove', function(){
		var id = getId(this.id);
		$("#" + id).remove();
	});

	//enable filename box and show save,cancel and remove btns document on clicking edit document btn
	$(document).on("click", ".assigned_document_edit", function(){
		var id = getId(this.id);
		$(this).addClass('hide');
		$("#assigned_document_form_" + id).removeClass("hide");
		$("#assigned_document_remove_" + id).removeClass("hide");
		$("#assigned_document_cancel_" + id).removeClass("hide");
	});

	$(document).on("click", ".document_tool", function(){
		var id = getId(this.id);
		$("#assigned_document_tool_" + id).removeClass("hide");
	});

	$(document).on('click', '.document_group_tool_cancel', function(){
		var id = getId(this.id);
		$("#assigned_document_tool_" + id).addClass("hide");
	});

	$(document).on("click", ".separate_invoice", function(){
		$("#invoice_type").val('SEPARATE');
		$(".separate_invoice").addClass('btn-light-green').removeClass('btn-gray');
		$(".single_invoice").removeClass('btn-light-green').addClass('btn-gray');
	});

	$(document).on("click", ".single_invoice", function(){
		$("#invoice_type").val('ONE');
		$(".separate_invoice").removeClass('btn-light-green').addClass('btn-gray');
		$(".single_invoice").addClass('btn-light-green').removeClass('btn-gray');
	});

	$(document).on("click", ".confirm_billing_address", function(){
		$("#confirm_customer_billing_address").val(true);
		$(".confirm_billing_address").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_billing_address").removeClass('btn-red').addClass('btn-gray');
		$(".billing_address_info").removeClass('hide');
	});

	$(document).on("click", ".unconfirm_billing_address", function(){
		$("#confirm_customer_billing_address").val(false);
		$(".confirm_billing_address").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_billing_address").addClass('btn-red').removeClass('btn-gray');
		$(".billing_address_info").addClass('hide');
	});

	$(document).on("click", ".confirm_invoice_address", function(){
		$("#separate_invoice_address").val(true);
		$(".confirm_invoice_address").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_invoice_address").removeClass('btn-red').addClass('btn-gray');
		$(".invoice_address_info").removeClass('hide');
	});

	$(document).on("click", ".unconfirm_invoice_address", function(){
		$("#separate_invoice_address").val(false);
		$(".confirm_invoice_address").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_invoice_address").addClass('btn-red').removeClass('btn-gray');
		$(".invoice_address_info").addClass('hide');
	});

	$(document).on("click", ".confirm_e_invoice_address", function(){
		$("#e_invoice_address").val(true);
		$(".confirm_e_invoice_address").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_e_invoice_address").removeClass('btn-red').addClass('btn-gray');
		$(".e_invoice_address_info").removeClass('hide');
	});

	$(document).on("click", ".unconfirm_e_invoice_address", function(){
		$("#e_invoice_address").val(false);
		$(".confirm_e_invoice_address").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_e_invoice_address").addClass('btn-red').removeClass('btn-gray');
		$(".e_invoice_address_info").addClass('hide');
	});

	$(document).on("click", ".mail_billing_channel", function(){
		$("#confirm_billing_channel").val("MAIL");
		$(".mail_billing_channel").addClass('btn-primary').removeClass('btn-gray');
		$(".email_billing_channel").removeClass('btn-primary').addClass('btn-gray');
		$(".einvoice_billing_channel").removeClass('btn-primary').addClass('btn-gray');
	});

	$(document).on("click", ".email_billing_channel", function(){
		$("#confirm_billing_channel").val("EMAIL");
		$(".mail_billing_channel").removeClass('btn-primary').addClass('btn-gray');
		$(".email_billing_channel").addClass('btn-primary').removeClass('btn-gray');
		$(".einvoice_billing_channel").removeClass('btn-primary').addClass('btn-gray');
	});

	$(document).on("click", ".einvoice_billing_channel", function(){
		$("#confirm_billing_channel").val("E_INVOICE");
		$(".mail_billing_channel").removeClass('btn-primary').addClass('btn-gray');
		$(".email_billing_channel").removeClass('btn-primary').addClass('btn-gray');
		$(".einvoice_billing_channel").addClass('btn-primary').removeClass('btn-gray');
	});

	$(document).on("click", ".confirm_legal_expense_insurance", function(){
		$("#legal_expence_insurance").val(true);
		$(".confirm_legal_expense_insurance").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_legal_expense_insurance").removeClass('btn-red').addClass('btn-gray');
		$(".show_insurance_information").removeClass('hide');
	});

	$(document).on("click", ".unconfirm_legal_expense_insurance", function(){
		$("#legal_expence_insurance").val(false);
		$(".confirm_legal_expense_insurance").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_legal_expense_insurance").addClass('btn-red').removeClass('btn-gray');
		$(".show_insurance_information").addClass('hide');
	});

	$(document).on("click", ".confirm_company_billing_address", function(){
		$("#check_company_billing_address").val(true);
		$(".confirm_company_billing_address").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_company_billing_address").removeClass('btn-red').addClass('btn-gray');
		$(".company_billing_address_info").removeClass('hide');
	});

	$(document).on("click", ".unconfirm_company_billing_address", function(){
		$("#check_company_billing_address").val(false);
		$(".confirm_company_billing_address").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_company_billing_address").addClass('btn-red').removeClass('btn-gray');
		$(".company_billing_address_info").addClass('hide');
	});

	$(document).on("click", ".confirm_company_invoice_address", function(){
		$("#check_separate_invoice_address").val(true);
		$(".confirm_company_invoice_address").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_company_invoice_address").removeClass('btn-red').addClass('btn-gray');
		$(".company_invoice_address_info").removeClass('hide');
	});

	$(document).on("click", ".unconfirm_company_invoice_address", function(){
		$("#check_separate_invoice_address").val(false);
		$(".confirm_company_invoice_address").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_company_invoice_address").addClass('btn-red').removeClass('btn-gray');
		$(".company_invoice_address_info").addClass('hide');
	});

	$(document).on("click", ".confirm_company_e_invoice_address", function(){
		$("#check_e_invoice_address").val(true);
		$(".confirm_company_e_invoice_address").addClass('btn-light-green').removeClass('btn-gray');
		$(".unconfirm_company_e_invoice_address").removeClass('btn-red').addClass('btn-gray');
		$(".company_e_invoice_address_info").removeClass('hide');
	});

	$(document).on("click", ".unconfirm_company_e_invoice_address", function(){
		$("#check_e_invoice_address").val(false);
		$(".confirm_company_e_invoice_address").removeClass('btn-light-green').addClass('btn-gray');
		$(".unconfirm_company_e_invoice_address").addClass('btn-red').removeClass('btn-gray');
		$(".company_e_invoice_address_info").addClass('hide');
	});

	$(document).on("click", ".mail_primary_billing_channel", function(){
		$("#confirm_primary_billing_channel").val("MAIL");
		$(".mail_primary_billing_channel").addClass('btn-primary').removeClass('btn-gray');
		$(".email_primary_billing_channel").removeClass('btn-primary').addClass('btn-gray');
		$(".einvoice_primary_billing_channel").removeClass('btn-primary').addClass('btn-gray');
	});

	$(document).on("click", ".email_primary_billing_channel", function(){
		$("#confirm_primary_billing_channel").val("EMAIL");
		$(".mail_primary_billing_channel").removeClass('btn-primary').addClass('btn-gray');
		$(".email_primary_billing_channel").addClass('btn-primary').removeClass('btn-gray');
		$(".einvoice_primary_billing_channel").removeClass('btn-primary').addClass('btn-gray');
	});

	$(document).on("click", ".einvoice_primary_billing_channel", function(){
		$("#confirm_primary_billing_channel").val("EINVOICE");
		$(".mail_primary_billing_channel").removeClass('btn-primary').addClass('btn-gray');
		$(".email_primary_billing_channel").removeClass('btn-primary').addClass('btn-gray');
		$(".einvoice_primary_billing_channel").addClass('btn-primary').removeClass('btn-gray');
	});

	$(document).on("click", ".confirm_payer", function(){
		var payer = $(this).val();
		$("#invoice_payer_ids").val(payer);
	});

	$("#service_subway_previous_btn").click(function(){
		var current_step = $("#service_subway_current_step").text().trim();
		$(".service_subway_slide_button").text(parseInt(current_step) - 1);
		var update_previous_step = parseInt(current_step) - 2;
		var updated_next_step = parseInt(current_step);
		var updated_current_step = parseInt(current_step) - 1;
		$("#service_subway_slide_content_step_" + updated_current_step).removeClass("hide");
		$("#service_subway_slide_content_step_" + current_step).addClass("hide");
		if (updated_current_step >= 1){
			updateServiceSubwaySteps(update_previous_step, updated_current_step, updated_next_step);
		}
	});

	$("#service_subway_next_btn").click(function(){
		var current_step = $("#service_subway_current_step").text().trim();
		$(".service_subway_slide_button").text(parseInt(current_step) + 1);
		var updated_next_step = parseInt(current_step) + 2;
		var update_previous_step = parseInt(current_step);
		var updated_current_step = parseInt(current_step) + 1;
		$("#service_subway_slide_content_step_" + updated_current_step).removeClass("hide");
		$("#service_subway_slide_content_step_" + current_step).addClass("hide");
		if (updated_current_step <= 6){
			updateServiceSubwaySteps(update_previous_step, updated_current_step, updated_next_step)
		}
	});

	$(document).on("click", ".set_delete_deliver_checkbox_check", function(){
		var id = getId(this.id);
		var parentEl = $(this).parent();
		var value = $(this).val();
		if ($(this).is(":checked")){
			$(".set_delete_deliver_checkbox_check").prop('checked', false);
			$(this).prop('checked', true);
			$('.select_text').removeClass('hide');
			$('.unselect_text').addClass('hide');
			$(parentEl).find('.select_text').addClass('hide')
			$(parentEl).find('.unselect_text').removeClass('hide');
			$('#confirm_checkbox').removeClass('disableattchmentbutton');
			if (value == 'delete'){
				$('#press_to_delete').removeClass('hide');
				$('#press_to_deliver').addClass('hide');
			} else {
				$('#press_to_deliver').removeClass('hide');
				$('#press_to_delete').addClass('hide');
			}
		} else {
			$('.select_text').removeClass('hide');
			$('.unselect_text').addClass('hide');
			$('#press_to_deliver').addClass('hide');
			$('#press_to_delete').addClass('hide');
			$('#confirm_checkbox').addClass('disableattchmentbutton');
		}
	});

	$(document).on("click", ".attorney_checkbox_check", function(){
		var id = getId(this.id);
		var parentEl = $(this).parent();
		if ($(this).is(":checked")){
			$(".attorney_checkbox_check").prop('checked', false);
			$(this).prop('checked', true);
			$('.select_text').removeClass('hide');
			$('.unselect_text').addClass('hide');
			$('.attorneyBox').addClass('whiteBg');
			$('.attorneyBox').removeClass('darkGrayBgBox');
			$(parentEl).find('.select_text').addClass('hide')
			$(parentEl).find('.unselect_text').removeClass('hide');
			$('#attorneyBox_' + id).addClass('darkGrayBgBox');
			$('#attorneyBox_' + id).removeClass('whiteBg');
			$(".attorney_count_check").removeClass('disableattchmentbutton');
			$(".save_attorney_after_changing").removeClass('disableattchmentbutton');
		} else {
			$(".chkAllPotentialList").prop('checked', this.checked);
			$(".chkPotentialListing").prop('checked', this.checked);
			$('#attorneyBox_' + id).removeClass('darkGrayBgBox');
			$('#attorneyBox_' + id).addClass('whiteBg');
			$('.select_text').removeClass('hide');
			$('.unselect_text').addClass('hide');
			$(".attorney_count_check").addClass('disableattchmentbutton');
			$(".save_attorney_after_changing").addClass('disableattchmentbutton');
		}
	});

	$(document).on("click", ".attorney_checkbox", function(){
		var parentEl = $(this).parent();
		var value = $(this).val();
		if ($(this).is(":checked")){
			$(".attorney_checkbox").prop('checked', false);
			$(this).prop('checked', true);
			$('.select_text').removeClass('hide');
			$('.unselect_text').addClass('hide');
			$(parentEl).find('.select_text').addClass('hide')
			$(parentEl).find('.unselect_text').removeClass('hide');
			$('#GetcheckBox').removeClass('disableattchmentbutton');
			if (value == "agent"){
				$('#confirmBox2').removeClass('hide');
				$('#confirmBox1').addClass('hide');
			} else {
				$('#confirmBox1').removeClass('hide');
				$('#confirmBox2').addClass('hide');
			}
		} else {
			$('.select_text').removeClass('hide');
			$('.unselect_text').addClass('hide');
			$('#confirmBox1').addClass('hide');
			$('#confirmBox2').addClass('hide');
			$('#GetcheckBox').addClass('disableattchmentbutton');
		}
	});

	$(document).on("click", ".office_check", function(){
		var id = getId(this.id);
		if ($("#office_check_" + id).is(":checked")) {
			//add to the actual param
			$(".office_check").prop('checked', false);
			$(this).prop('checked', true);
			$('.office_select_text').removeClass('hide');
			$('.office_unselect_text').addClass('hide');
			$('.officeBox').addClass('whiteBg');
			$('.officeBox').removeClass('darkGrayBgBox');
			set_unselect_text("#office_select_text_" + id, "#office_unselect_text_" + id);
			$('#officeBox_' + id).addClass('darkGrayBgBox');
			$('#officeBox_' + id).removeClass('whiteBg');
			$("#selected_offices_list").val(id);
			$(".attorney_count_check").removeClass('disableattchmentbutton');
			// update to unselect text
		} else {
			// remove from actual param
			$("#selected_offices_list").val(id);
			// update to select text
			$('#officeBox_' + id).removeClass('darkGrayBgBox');
			$('#officeBox_' + id).addClass('whiteBg');
			set_select_text("#office_select_text_" + id, "#office_unselect_text_" + id);
			$(".attorney_count_check").addClass('disableattchmentbutton');
		}
	});


	//update the actual selected leads in manual search
	$(document).on("click", ".lead_check", function(){
		var id = getId(this.id);
		//if checked
		if ($(this).is(":checked")) {
			if ($(".lead_check:checkbox:checked").length >= 1){
    		$("#confirm_contact_recipents").removeClass("disableattchmentbutton");
	  	}
			// update to unselect text
			set_unselect_text("#lead_select_text_" + id, "#lead_unselect_text_" + id);
		} else {
			// update to select text
			set_select_text("#lead_select_text_" + id, "#lead_unselect_text_" + id);
			if ($(".lead_check:checkbox:checked").length = 0){
    		$("#confirm_contact_recipents").addClass("disableattchmentbutton");
	  	}
		}
		var final_leads = $("#final_leads").val().split(",");
		var final_lead_index = final_leads.indexOf(id);
		if ($("#lead_check_" + id).is(":checked")){
			if (final_lead_index < 0){
				final_leads.push(id);
			}
		} else {
			if (final_lead_index > -1){
				final_leads.splice(final_lead_index, 1);
			}
		}
		final_leads = final_leads.filter(Boolean);
		$("#final_leads").val(final_leads.join(","));
		updateLeadReassignerSubmitBtnStatus();
	});

	$(document).on("click", ".confirm_email_notification", function(){
		var id = getId(this.id);
		if ($(this).hasClass("btn-primary")){
			$("#email_notify").val(false);
			$("#email_notification_" + id).removeClass('btn-primary').addClass('btn-gray');
		} else {
			$("#email_notify").val(true);
			$("#email_notification_" + id).addClass('btn-primary').removeClass('btn-gray');
		}
	});

	$(document).on("click", ".confirm_contact_reassignment", function(){
		var id = getId(this.id);
		if ($(this).hasClass("btn-red")){
			$("#allow_contact_reassignment").val(true);
			$("#allow_contact_reassignment_" + id).removeClass('btn-red').addClass('btn-light-green');
			$("#allow_reassignment_" + id).toggleClass("hide");
			$("#disallow_reassignment_" + id).toggleClass("hide");
		} else {
			$("#allow_contact_reassignment").val(false);
			$("#allow_contact_reassignment_" + id).addClass('btn-red').removeClass('btn-light-green');
			$("#allow_reassignment_" + id).toggleClass("hide");
			$("#disallow_reassignment_" + id).toggleClass("hide");
		}
	});

	$(document).on("click", ".confirm_private_customer", function(){
		$("#contact_customer_type").val("PRIVATE_CUSTOMER");
		$(".confirm_private_customer").removeClass('btn-gray').addClass('btn-primary');
		$(".confirm_company_customer").addClass('btn-gray').removeClass('btn-primary');
		$(".confirm_legal_aid_customer").addClass('btn-gray').removeClass('btn-primary');
	});

	$(document).on("click", ".confirm_company_customer", function(){
		$("#contact_customer_type").val("COMPANY_CUSTOMER");
		$(".confirm_private_customer").addClass('btn-gray').removeClass('btn-primary');
		$(".confirm_company_customer").removeClass('btn-gray').addClass('btn-primary');
		$(".confirm_legal_aid_customer").addClass('btn-gray').removeClass('btn-primary');
	});

	$(document).on("click", ".confirm_legal_aid_customer", function(){
		$("#contact_customer_type").val("LEGAL_AID_CUSTOMER");
		$(".confirm_private_customer").addClass('btn-gray').removeClass('btn-primary');
		$(".confirm_company_customer").addClass('btn-gray').removeClass('btn-primary');
		$(".confirm_legal_aid_customer").removeClass('btn-gray').addClass('btn-primary');
	});

	$(document).on("click", ".new_assignment_check", function(){
		var id = getId(this.id);
		if ($(this).is(":checked")){
			$("#biller_button_"+ id).addClass("hide");
		} else {
			$("#biller_button_"+ id).removeClass("hide");
		}
	});

	$(document).on("click", ".collegues_call_check", function(){
		var id = getId(this.id);
		if ($(this).is(":checked")){
			$("#customer_card_button_"+ id).addClass("hide");
			$("#biller_button_"+ id).addClass("hide");
		}else{
			$("#customer_card_button_"+ id).removeClass("hide");
			$("#biller_button_"+ id).removeClass("hide");

		}
	});

	$(document).on("click", "#update_customer_information", function(){
		var selected_customers_ids = [];
		$.each($("input[name='customers[]']:checked"), function(){
				selected_customers_ids.push($(this).val());
		});
		if (selected_customers_ids.length >= 1 && selected_customers_ids.length <= 3){
			url = "/assignments/customer_contact_info";
			$.ajax({
				url: url,
				type: 'POST',
				data: {selected_customers_ids: selected_customers_ids}
			});
		}
	});

	$(document).on("click", ".confirmed_copy_customer", function(){
		var parentEl = $(this).closest('#first_customer_contact_info');
		var phone_number = $(parentEl).find(".phone_number").val();
		var address = $(parentEl).find(".address").val();
		var post_number = $(parentEl).find(".post_number").val();
		var city = $(parentEl).find(".city").val();
		var parent_id = $(parentEl).find(".parent_id").val();
		$(".phone_number").val(phone_number);
		$(".address").val(address);
		$(".post_number").val(post_number);
		$(".city").val(city);
		$(".parent_id").val(parent_id);
		$("#show_copy_note").addClass("hide");
	});

	$(document).on("click", ".call_back_type", function(){
		var call_back_type = $(this).val();
		if (call_back_type == "ACTIVE_ASSIGNMENT"){
			$(".customer_register").removeClass("hide");
			$(".inspector_register_btn").addClass("hide");
		}else{
			$(".customer_register").addClass("hide");
			$(".inspector_register_btn").removeClass("hide");
		}
	});

	$(document).on("click", "#add_call_back_info", function(){
		$(this).toggleClass("hide");
		$(".call_back_additional_info").toggleClass("hide");
	});

	$(document).on("click", ".contact_person_check", function(){
		var id = getId(this.id);
		//if checked
		if ($(this).is(":checked")) {
			// update to unselect text
			$('.select_customer_text').removeClass('hide');
			$('.unselect_customer_text').addClass('hide');
			set_unselect_text("#select_contact_person_" + id, "#unselect_contact_person_" + id);
			$('.save_choosen_customer').removeClass('disableattchmentbutton');
		} else {
			// update to select text
			set_select_text("#select_contact_person_" + id, "#unselect_contact_person_" + id);
			$('.save_choosen_customer').addClass('disableattchmentbutton');
		}
	});

	$(document).on("click", ".request_recipient_check", function(){
		var id = getId(this.id);
		//if checked
		if ($(this).is(":checked")) {
			// update to unselect text
			$('.select_recipient_text').removeClass('hide');
			$('.unselect_recipient_text').addClass('hide');
			set_unselect_text("#select_recipient_" + id, "#unselect_recipient_" + id);
		} else {
			// update to select text
			set_select_text("#select_recipient_" + id, "#unselect_recipient_" + id);
		}
	});

	$(".nav-sidebar").on("click", ".closeTab", function () {
		var TabId = $(this).parent('a').attr('href');
		var id = getId(TabId);
		if (TabId == ('#new_note_'+id)){
			confirm_remove  = confirm(I18n.t("js.general.task.delete_content"));
			if (confirm_remove){
				$('#assignment_'+id+'__destroy').val(1);
				$(TabId).addClass('hide');
				$(this).parent().addClass('hide');
			}
		} else {
			$(this).parent().remove();
			$(TabId).remove();
		}
		$('#tabs a:first').tab('show');
	});

	$('#right-button').click(function(e) {
		e.preventDefault();
		$('#content').animate({
			scrollLeft: "+=200px"
		}, "slow");
	});

	 $('#left-button').click(function(e) {
		e.preventDefault();
		$('#content').animate({
			scrollLeft: "-=200px"
		}, "slow");
	});

	if ($("#team_members").length > 0){
		var total_step = $("#team_members").find($("input")).length/3;
		$(document).on("click", "#add_legal_team_member",  function() {
			var count = ++total_step;
			$('#input_add_legal_team_member').append('<div class="added_team_member" id="added_team_member_wrapper_' + count + '"><div class="row row7"><div class="col-12 col-sm-6 form-group"><input type="text" id="input_add_name_'+count+'" name="legal_assistant_info[member_'+ count +'][name]" class="form-control" placeholder = "Name of Legal Assistant"/></div><div class="col-12 col-sm-6 form-group"><input type="text" id="input_add_phone_number_'+count+'" name="legal_assistant_info[member_'+ count +'][phone_number]" class="form-control" placeholder = "Phone number"/></div><div class="col-12 col-sm-6 form-group"><input type="text" id="input_add_email_'+count+'" name="legal_assistant_info[member_'+ count +'][email]" class="form-control" placeholder = "Email"/></div><div class="col-12 col-sm-6 text-right pt-md-2 form-group"><a href="javascript:void(0)" class="link-14 text-blue remove_fields dynamic remove_team_member" id="remove_team_member_' + count + '"><i class="removeIcon"></i>Remove</a></div></div></div>')
		});
		$(document).on("click",".remove_team_member", function() {
			var idArr = this.id.split("_");
			var id = idArr[idArr.length - 1];
			$("#added_team_member_wrapper_"+ id).remove();
		});
	}

	if ($("#contact_persons").length > 0){
		var total_step = $("#contact_persons").find($("input")).length/4;
		$(document).on("click", "#add_contact_person",  function() {
			var count = ++total_step;
			$('#input_add_contact_person').append('<div class="added_contact_person" id="added_contact_person_wrapper_' + count + '"><div class="row row7"><div class="col-12 col-sm-6 form-group"><input type="text" id="input_add_name_'+count+'" name="contact_person[member_'+ count +'][name]" class="form-control" placeholder = "Name"/></div><div class="col-12 col-sm-6 form-group"><input type="text" id="input_add_title_'+count+'" name="contact_person[member_'+ count +'][title]" class="form-control" placeholder = "Title"/></div><div class="col-12 col-sm-6 form-group"><input type="text" id="input_add_phone_number_'+count+'" name="contact_person[member_'+ count +'][phone_number]" class="form-control" placeholder = "Phone number"/></div><div class="col-12 col-sm-6 form-group"><input type="text" id="input_add_email_'+count+'" name="contact_person[member_'+ count +'][email]" class="form-control" placeholder = "Email"/></div><div class="col-12 col-sm-6 form-group"><a href="javascript:void(0)" class="link-14 text-blue remove_fields dynamic remove_contact_person" id="remove_contact_person_' + count + '"><i class="removeIcon"></i>Remove contact person</a></div></div></div>')
		});
		$(document).on("click",".remove_contact_person", function() {
			var idArr = this.id.split("_");
			var id = idArr[idArr.length - 1];
			$("#added_contact_person_wrapper_"+ id).remove();
		});
	}

	$(document).on("click", ".save_customer_data", function(){
		$('.show_additional_info').toggleClass("hide");
	});

	$(document).on("change", ".data_status", function(){
    var data_status = $(this).val()
    if ( data_status == "ALL_DATA_DELETED" ){
      $(".all_data_deleted").removeClass("hide")
      $(".no_data_deleted").addClass("hide")
      $(".some_data_deleted").addClass("hide")
      $(".all_data_deliver").addClass("hide")
    } else if ( data_status == "NO_DATA_COULD_BE_DELETED" ) {
      $(".all_data_deleted").addClass("hide")
      $(".no_data_deleted").removeClass("hide")
      $(".some_data_deleted").addClass("hide")
      $(".all_data_deliver").addClass("hide")
    } else if ( data_status == "SOME_DATA_DELETED" ) {
      $(".all_data_deleted").addClass("hide")
      $(".no_data_deleted").addClass("hide")
      $(".some_data_deleted").removeClass("hide")
      $(".all_data_deliver").addClass("hide")
    } else {
      $(".all_data_deleted").addClass("hide")
      $(".no_data_deleted").addClass("hide")
      $(".some_data_deleted").addClass("hide")
      $(".all_data_deliver").removeClass("hide")
    }
  });

  $(document).on('keyup', '.calculate_fraction, .calculate_percentage', function(e){
  	var assignment_selected_customers = $("#assignment_selected_customers").val();
  	var assignment_selected_customers = assignment_selected_customers.toString().split(" ");
  	payer_share = $(this).val();
  	var id = getId(this.id);
  	if(assignment_selected_customers.length > 1){
  		assignment_selected_customers.splice( $.inArray(id, assignment_selected_customers), 1 );
  	}
  	var remain_share = 100 - checkNumericValue(payer_share);
  	if(assignment_selected_customers.length > 0){
  		var share = checkNumericValue(remain_share)/checkNumericValue(assignment_selected_customers.length)
  	}
  	$.each(assignment_selected_customers, function(index, value) {
      $("#invoice_share_"+value).val(share);
    });
    parent_owner = $(this).closest('.ownershipParent')
    if ($(this).hasClass('calculate_fraction')){
      result = calculate_percentage_from_fraction($(this).val().toString())
      parent_owner.find('.calculate_percentage').val(result).trigger('change');
    } else {
      result = calculate_fraction_from_percentage($(this).val())
      parent_owner.find('.calculate_fraction').val(result).trigger('change');
    }
    checkSumOfCalculateFraction(e,this)
  });

});

function checkSumOfCalculateFraction(e,element){
  var form = $(element).closest('form')
  var parentClass = $(element).closest('.calculate_fraction_and_percentage_both')
  var fraction_Transfer_tax_fields = parentClass.find('.calculate_fraction');
  var percentage_Transfer_tax_fields = parentClass.find('.calculate_percentage');
  if (fraction_Transfer_tax_fields.length > 0) {
    var msg_f = I18n.t("js.general.task.total_must_be_less_or_equal_to_1")
    var isTransferTaxValid_f = checkIfSumEqualToOne(fraction_Transfer_tax_fields);
    displayRemoveValidationMsg(form,e,msg_f,fraction_Transfer_tax_fields,isTransferTaxValid_f)
  }
  if (percentage_Transfer_tax_fields.length > 0) {
    var msg_p = I18n.t("js.general.task.total_must_be_less_or_equal_to_100")
    var isTransferTaxValid_p = checkIfPerSumEqualToOne(percentage_Transfer_tax_fields);
    displayRemoveValidationMsg(form,e,msg_p,percentage_Transfer_tax_fields,isTransferTaxValid_p)
  }
}
// function checkSumOfCalculateFraction(e,element){
//   var form = $(element).closest('form')
//   var parentClass = $(element).closest('.calculate_percentage_paremt')
//   var percentage_Transfer_tax_fields = parentClass.find('.calculate_percentage');
  
//   if (percentage_Transfer_tax_fields.length > 0) {
//     var msg_p = I18n.t("js.general.task.total_must_be_less_or_equal_to_100")
//     var isTransferTaxValid_p = checkIfPerSumEqualToOne(percentage_Transfer_tax_fields);
//     displayRemoveValidationMsg(form,e,msg_p,percentage_Transfer_tax_fields,isTransferTaxValid_p)
//   }
// }

function displayRemoveValidationMsg(form,e,msg,all_Transfer_tax_fields,isTransferTaxValid){
  if (isTransferTaxValid){
    $(all_Transfer_tax_fields).each(function() {
      remove_validation_text($(this));
    });
  } else {
    e.preventDefault();
    $(all_Transfer_tax_fields).each(function() {
      display_validation_text($(this),msg);
    });
  }
  if ($(form).find(".has-error").length > 0){
    $(form).find("button[type='submit']").prop('disabled',true);
  } else {
    $(form).find("button[type='submit']").prop('disabled',false);
  }
}

function display_validation_text(element,msg){
  $(element).parent().closest('div').addClass('has-error');
  $(element).parent().closest('div').find('small:eq(0)').remove();
  $(element).parent().closest('div').find('i:eq(0)').remove();
  $(element).parent().closest('div').append('<i class="form-control-feedback fa fa-remove" style="top: 0px;"></i>'); 
  $(element).parent().closest('div').append('<small class="help-block">'+msg+'</small>');
}

function remove_validation_text(element){
  $(element).parent().closest('div').removeClass('has-error');
  $(element).parent().closest('div').find('small:eq(0)').remove();
  $(element).parent().closest('div').find('i:eq(0)').remove();
}

function calculate_fraction_from_percentage(percent) {
  if (percent.match('%') != null){
    var split = percent.split('%');
    var result = (parseInt(split[0])/100);
  }else{
    var result = (percent/100);
  }
  return (convert_to_fraction(result))
}

function checkIfPerSumEqualToOne(elements){
  var isValid = true;
  var total_percentage = 0;
  $(elements).each(function() {
    if ( $(this).val() != '' ) {
      total_percentage += parseFloat($(this).val())
    }
  });
  if (total_percentage == 100) {
    isValid = true;
  } else {
    isValid = false; 
  }
  return isValid;
}

function convert_to_fraction(num, return_object = false){
  if (!num.toString().split('.')[1]){
    num = num.toFixed(2);
  }
  var decimal = num.toString();
  var decimalArray = decimal.split(".");
  var leftDecimalPart = decimalArray[0]; // 1
  var rightDecimalPart = decimalArray[1]; // 75
  var numerator = leftDecimalPart + rightDecimalPart // 175
  var denominator = Math.pow(10,rightDecimalPart.length); // 100
  var factor = highestCommonFactor(numerator, denominator); // 25
  denominator /= factor;
  numerator /= factor;
  if (return_object){
    return {numerator: numerator, denominator: denominator};
  } else {
    if (numerator == 1 && denominator == 1) {
      return numerator;
    } else if (numerator == 0 && denominator == 1) {
      return 0;
    } else {
      return (numerator+'/'+denominator);
    }
  }
}

function highestCommonFactor(a,b) {
  if (b==0) return a;
  return highestCommonFactor(b,a%b);
}

function updateServiceSubwaySteps(previous_step, current_step, next_step){
	if (next_step <= 6){
		$(".service_subway_next_step").addClass("hide");
		$("#service_subway_next_step_" + next_step).removeClass("hide").removeClass("disableattchmentbutton");
	}
	if (previous_step >= 1){
		$(".service_subway_previous_step").addClass("hide");
		$("#service_subway_previous_step_" + previous_step).removeClass("hide").removeClass("disableattchmentbutton");
	}
	if (current_step == 1){
		$(".service_subway_previous_step").addClass("hide");
	}
	$(".service_subway_current_step").addClass("hide");
	$("#service_subway_current_step_" + current_step).removeClass("hide");
	$("#service_subway_current_step").text(current_step);
	$(".service_subway_step_content").addClass("hide");
	$("#service_subway_step_content_" + current_step).removeClass("hide");
	$('.slideNext').removeClass('hide');
	var total_last_step = $("#total_service_subway_last_step").val();
	if (next_step >= total_last_step){
		$('.slideNext').addClass('hide');
	}
}

function getId(id){
	var idArr = id.split("_");
	return idArr[idArr.length - 1];
}

//show unselect text, hide select text
function set_unselect_text(selectId, unselectId) {
	$(selectId).addClass('hide');
	$(unselectId).removeClass('hide');
}

//show select text, hide unselect text
function set_select_text(selectId, unselectId) {
	$(selectId).removeClass('hide');
	$(unselectId).addClass('hide');
}

function updateLeadReassignerSubmitBtnStatus(){
	if ($("#final_leads").val() == ""){
		$("#lead_reassigner_filter_step_2_btn").attr("disabled", true);
	} else {
		$("#lead_reassigner_filter_step_2_btn").attr("disabled", false);
	}
}

function updateLeadReassignerConfrimLeadStatus(){
	if ($(".confirm_lead:checkbox:checked").length > 0){
		$("#lead_reassigner_step_3_btn").attr("disabled", false);
	} else {
		$("#lead_reassigner_step_3_btn").attr("disabled", true);
	}
}

function checkNumericValue(number, decimal_points = 2) {
  if (number){
    return parseFloat(parseFloat(number.toString().replace(' ', '').replace(",", ".")).toFixed(decimal_points));
  }
  else{
    return 0;
  }
}

function setFlashMessage(parentId, flashId, msg, fadeOut, animate){
  $("#" + parentId).html(getFlashMessageEle(msg, flashId));
  if (animate != false){
    SetFocusOnTopOfPage()
    //$('html, body').animate({scrollTop:$('#' + parentId).position().top}, 'slow');
  }
  if (fadeOut != false) {
    fadeOutFlashMessage(flashId);
  }
}

function getFlashMessageEle(msg, flashId){
  return "<div id='" + flashId + "' class='alert alert-dismissible alert-success'><button class='close' data-dismiss='alert' type='button'>×</button>" + msg + "</div>"
}

function SetFocusOnTopOfPage(){
  $(".modal").animate({ scrollTop: 0 }, "slow");
}

function fadeOutFlashMessage(id){
  $("#" + id).delay(3000).fadeOut(500);
}
