$(document).ready(function(){

  $(document).on("click", ".confirm_written_agreement", function(){
    $("#confirm_written_assignment_agreement").val(true);
    $(".confirm_written_agreement").addClass('btn-primary').removeClass('btn-gray');
    $(".unconfirm_written_agreement").removeClass('btn-primary').addClass('btn-gray');
  });

  $(document).on("click", ".unconfirm_written_agreement", function(){
    $("#confirm_written_assignment_agreement").val(false);
    $(".confirm_written_agreement").removeClass('btn-primary').addClass('btn-gray');
    $(".unconfirm_written_agreement").addClass('btn-primary').removeClass('btn-gray');
  });

  $(document).on("click", ".confirmed_identity_pep", function(){
    $("#confirm_assignment_identity").val(true);
    $(".confirmed_identity_pep").addClass('btn-primary').removeClass('btn-gray');
    $(".unconfirmed_identity_pep").removeClass('btn-primary').addClass('btn-gray');
  });

  $(document).on("click", ".unconfirmed_identity_pep", function(){
    $("#confirm_assignment_identity").val(false);
    $(".confirmed_identity_pep").removeClass('btn-primary').addClass('btn-gray');
    $(".unconfirmed_identity_pep").addClass('btn-primary').removeClass('btn-gray');
  });

  $(document).on("click", ".acting_capacity_of_4", function(){
    $( "#another_acting_capacity_of" ).removeClass( "hide" )
  });

  $(document).on("click", ".acting_capacity_of_1", function(){
    $( "#another_acting_capacity_of" ).addClass( "hide" )
  });

  $(document).on("click", ".acting_capacity_of_2", function(){
    $( "#another_acting_capacity_of" ).addClass( "hide" )
  });

  $(document).on("click", ".acting_capacity_of_3", function(){
    $( "#another_acting_capacity_of" ).addClass( "hide" )
  });

  $(document).on("click", ".pep_value_1", function(){
    $( ".pep_values" ).addClass( "hide" )
  });

  $(document).on("click", ".pep_value_2", function(){
    $( ".pep_values" ).removeClass( "hide" )
  });

  $(document).on("click", ".nextlink1", function(){
    if ($("#go_to_next_form_1").hasClass("verification1")){
      $( "#pep_form_1" ).removeClass( "active in" )
      $( "#pep_form_2" ).addClass( "active in" )
      $( ".pep2" ).addClass( "active" )
      $( ".pep1" ).removeClass( "active" )
    }
    if ($("#go_to_next_form_1").hasClass("benificial1")){
      $( "#pep_form_1" ).removeClass( "active in" )
      $( "#pep_form_3" ).addClass( "active in" )
      $( ".pep3" ).addClass( "active" )
      $( ".pep1" ).removeClass( "active" )
    }
  });

  $(document).on("click", ".nextlink2", function(){
    if ($("#go_to_next_form_2").hasClass("report2")){
      $( "#pep_form_2" ).removeClass( "active in" )
      $( "#pep_form_3" ).addClass( "active in" )
      $( ".pep3" ).addClass( "active" )
      $( ".pep2" ).removeClass( "active" )
    }
  });

  $(document).on("click", ".previouslink2", function(){
    if ($("#go_to_previous_form_2").hasClass("origin2")){
      $( "#pep_form_2" ).removeClass( "active in" )
      $( "#pep_form_1" ).addClass( "active in" )
      $( ".pep1" ).addClass( "active" )
      $( ".pep2" ).removeClass( "active" )
    }
  });

  $(document).on("click", ".previouslink3", function(){
    if ($("#go_to_previous_form_3").hasClass("origin3")){
      $( "#pep_form_3" ).removeClass( "active in" )
      $( "#pep_form_1" ).addClass( "active in" )
      $( ".pep1" ).addClass( "active" )
      $( ".pep3" ).removeClass( "active" )
    }
    if ($("#go_to_previous_form_3").hasClass("verification3")){
      $( "#pep_form_3" ).removeClass( "active in" )
      $( "#pep_form_2" ).addClass( "active in" )
      $( ".pep2" ).addClass( "active" )
      $( ".pep3" ).removeClass( "active" )
    }
  });

  if ($("#add_agreement_attchments").length > 0){
    var matters = 0;
    $(document).on("click", "#add_agreement_attchments",  function() {
      var count = ++matters;
      $('#input_add_agreement_attchments').append('<div class="added_attachment" id="added_attachment_wrapper_' + count + '"><div class="row"><div class="col-12 col-sm-4"><div class="form-group form-group-label" id="attachment_name"><input type="text" id="input_add_agreement_attchments_'+count+'" placeholder="Name of Attachment" name="attachment[attachment_'+ count +'][name]" class="form-control"/><label for="attachment_name">Name of Attachment</label></div></div><div class="col-12 col-sm-4"><div class="form-group"><button class="btn btn-transparent btn-md btn-block border border-primary pt-3 btn-file text-blue"><input type="file" id="input_add_agreement_attchments_'+count+'" name="attachment[attachment_'+ count +'][file]" class="form-file"/>pdf-attachment</button></div></div><div class="col-12 col-sm-2"><div class="form-group text-right pt10"><label class="remove_attachment"  id="remove_attachment_' + count + '"><a href="javascript:void(0)" class=" link-14 text-blue remove_fields dynamic"><i class="removeIcon"></i>Remove</a></label></div></div></div></div>')
    });
    $(document).on("click",".remove_attachment", function() {
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      $("#added_attachment_wrapper_"+ id).remove();
    });
  }

	//add assignment agreements
  if ($("#add_matter").length > 0){
    var matters = 0;
    $(document).on("click", "#add_matter",  function() {
      var count = ++matters;
    	$('#input_add_matter').append('<div class="added_matter" id="added_matter_wrapper_' + count + '"><div class="row"><div class="col-12 col-sm-8 form-group"><input type="text" id="input_add_matter_'+count+'" name="matter[matter_'+ count +'][name]" class="form-control"/></div><div class="col-12 col-sm-2 form-group text-right pt10"><label class="remove_matter"  id="remove_matter_' + count + '"><a href="javascript:void(0)" class=" link-14 text-blue remove_fields dynamic"><i class="removeIcon"></i>Remove</a></label></div></div></div>')
    });
    $(document).on("click",".remove_matter", function() {
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      $("#added_matter_wrapper_"+ id).remove();
    });
  }

  $(document).on("click", "#agreement_previous_btn", function(){
    var agreement_current_tab = $(this).closest(".agreement_current_tab_check").find(".agreement_current_tab").text().trim();
    var current_tab = parseInt(agreement_current_tab);
    var previous_tab = parseInt(agreement_current_tab) - 1;
    if (previous_tab == 1){
      $('#base_form').addClass("fade show active");
      $('.first_agreement_tab').addClass("active");
    } else {
      $('#agreement_terms_' + previous_tab).addClass("fade show active");
      $('#agreement_terms_li_' + previous_tab).addClass("active");
    }
    $('#agreement_terms_' + current_tab).removeClass("fade show active");
    $('#agreement_terms_li_' + current_tab).removeClass("active");
    $(".agreement_current_tab").text(previous_tab);
  });

  $(document).on("click", "#agreement_next_btn", function(){
    var agreement_current_tab = $(this).closest(".agreement_current_tab_check").find(".agreement_current_tab").text().trim();
    var current_tab = parseInt(agreement_current_tab);
    var next_tab = parseInt(agreement_current_tab) + 1;
    if (current_tab == 1){
      $('#base_form').removeClass("fade show active");
      $('.first_agreement_tab').removeClass("active");
    } else {
      $('#agreement_terms_' + current_tab).removeClass("fade show active");
      $('#agreement_terms_li_' + current_tab).removeClass("active");
    }
    $('#agreement_terms_' + next_tab).addClass("fade show active");
    $('#agreement_terms_li_' + next_tab).addClass("active");
    $(".agreement_current_tab").text(next_tab);
  });

    $(document).on("click", ".origin_set_checkbox_check", function(){
    if ($(this).is(":checked")){
      $(".origin_set_checkbox_check").prop('checked', true);
      $(this).prop('checked', true);
      $('.select_text_1').removeClass('hide');
      $('.unselect_text_1').addClass('hide');
      $('.select_text_1').addClass('hide')
      $('.unselect_text_1').removeClass('hide');
    }
    else{
      $('.select_text_1').removeClass('hide');
      $('.unselect_text_1').addClass('hide');
    }
  });

  $(document).on("click", ".verification_set_checkbox_check", function(){
    if ($(this).is(":checked")){
      $(".verification_set_checkbox_check").prop('checked', true);
      $(this).prop('checked', true);
      $('.select_text_2').removeClass('hide');
      $('.unselect_text_2').addClass('hide');
      $('.select_text_2').addClass('hide')
      $('.unselect_text_2').removeClass('hide');
    }
    else{
      $('.select_text_2').removeClass('hide');
      $('.unselect_text_2').addClass('hide');
    }
  });

  $(document).on("click", ".report_set_checkbox_check", function(){
    if ($(this).is(":checked")){
      $(".report_set_checkbox_check").prop('checked', true);
      $(this).prop('checked', true);
      $('.select_text_3').removeClass('hide');
      $('.unselect_text_3').addClass('hide');
      $('.select_text_3').addClass('hide')
      $('.unselect_text_3').removeClass('hide');
    }
    else{
      $('.select_text_3').removeClass('hide');
      $('.unselect_text_3').addClass('hide');
    }
  });

  $(document).on("click", ".designated_attachment_check", function(){
    if ($(this).is(':checked') == true){
      var data = $(this).closest('.attachment_fields_count').find('.file_attachments');
      data.removeClass("disableattchmentbutton")
    }
    else{
      var data = $(this).closest('.attachment_fields_count').find('.file_attachments');
      data.addClass("disableattchmentbutton")
    }
  });

  $(document).on("click", ".show_agreement_drop_down_menu", function(){
    var parentEl = $(this).parent().closest('.drop_down_data_list');
    var agreement_step = $(parentEl).data('class_selector');
    if ($(parentEl).find(agreement_step).hasClass('hide')){
      $('.display_drop_down_data').addClass('hide');
      $('.set_title_name_close').addClass('hide');
      $('.show_upside_arrow').addClass('hide');
      $('.set_title_name_open').removeClass('hide');
      $('.show_downside_arrow').removeClass('hide');
    }
    $(parentEl).find(agreement_step+'_set_title_name').toggleClass("hide");
    $(parentEl).find(agreement_step+'_show_upside_arrow').toggleClass("hide");
    $(parentEl).find(agreement_step).toggleClass("hide");
  });

});