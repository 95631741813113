$(document).ready(function(){

  // Record billing Edit Btn
  //show action form on clicking edit btn
  $(document).on("click", ".action_item_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#item_action_info_" + id).removeClass('hide');
    $("#action_price_" + id).removeAttr('disabled');
    $("#billable_amount_" + id).removeAttr('disabled');
    $("#action_date_hour_" + id).removeAttr('disabled');
    $("#action_billable_base_date_" + id).removeAttr('disabled');
  });

  $(document).on('click', '.action_remove', function(){
    var id = getId(this.id);
    $("#" + id).remove();
  });

  $(document).on("click", ".item_action_cancel", function(){
    var id = getId(this.id);
    $("#item_action_info_" + id).addClass('hide');
    $(".action_item_edit").removeClass("hide");
  });

  $(document).on("click", ".action_base_billing", function(){
    var id = getId(this.id);
    $("#action_price_" + id).removeAttr('disabled');
    $("#confirm_pricing_base_" + id).val("ACTION");
    $("#time_base_billing_" + id).removeClass('btn-primary').addClass('btn-gray');
    $("#action_base_billing_" + id).addClass('btn-primary').removeClass('btn-gray');
  });

  $(document).on("click", ".confirm_time", function(){
    $("#pricing_base_confirm").val("TIME");
    $(".confirm_time").addClass('btn-primary').removeClass('btn-gray');
    $(".confirm_action").removeClass('btn-primary').addClass('btn-gray');
  });

  $(document).on("click", ".confirm_action", function(){
    $("#pricing_base_confirm").val("ACTION");
    $(".confirm_time").removeClass('btn-primary').addClass('btn-gray');
    $(".confirm_action").addClass('btn-primary').removeClass('btn-gray');
  });

  //show product form on clicking edit btn
  $(document).on("click", ".product_item_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#product_name_" + id).removeAttr('disabled');
    $("#product_price_" + id).removeAttr('disabled');
    $("#product_billable_base_date_" + id).removeAttr('disabled');
    $("#item_product_info_" + id).removeClass('hide');
  });

  $(document).on("click", ".item_product_cancel", function(){
    var id = getId(this.id);
    $("#item_product_info_" + id).addClass('hide');
    $(".product_item_edit").removeClass("hide");
  });

  //show expense form on clicking edit btn
  $(document).on("click", ".expense_item_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#expense_price_" + id).removeAttr('disabled');
    $("#item_expense_info_" + id).removeClass('hide');
    $("#expense_billable_base_date_" + id).removeAttr('disabled');
  });

  $(document).on("click", ".item_expense_cancel", function(){
    var id = getId(this.id);
    $("#item_expense_info_" + id).addClass('hide');
    $(".expense_item_edit").removeClass("hide");
  });

  $(document).on('click', '.expense_remove', function(){
    var id = getId(this.id);
    $("#" + id).remove();
  });

  // Billable Items edit btn - Record Billing
  //show action form on clicking edit btn
  $(document).on("click", ".billable_action_item_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#billable_action_info_" + id).removeClass('hide');
    $("#billable_amount_" + id).removeAttr('disabled');
    $("#action_date_hour_" + id).removeAttr('disabled');
    $("#billable_base_date_" + id).removeAttr('disabled');
  });

  $(document).on("click", ".billable_action_cancel", function(){
    var id = getId(this.id);
    $("#billable_action_info_" + id).addClass('hide');
    $(".billable_action_item_edit").removeClass("hide");
  });

  //show product form on clicking edit btn
  $(document).on("click", ".billable_product_item_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#billable_product_price_" + id).removeAttr('disabled');
    $("#billable_product_info_" + id).removeClass('hide');
  });

  $(document).on("click", ".billable_product_cancel", function(){
    var id = getId(this.id);
    $("#billable_product_info_" + id).addClass('hide');
    $(".billable_product_item_edit").removeClass("hide");
  });

  //show expense form on clicking edit btn
  $(document).on("click", ".billable_expense_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#billable_expense_price_" + id).removeAttr('disabled');
    $("#billable_expense_info_" + id).removeClass('hide');
    $("#expense_billable_base_date_" + id).removeAttr('disabled');
  });

  $(document).on("click", ".billable_expense_cancel", function(){
    var id = getId(this.id);
    $("#billable_expense_info_" + id).addClass('hide');
    $(".billable_expense_edit").removeClass("hide");
  });

  // Total Billing Edit btn
  //Invoice Item edit btn
  $(document).on("click", ".invoice_item_edit", function(){
    var id = getId(this.id);
    $("#total_invoice_items_" + id).addClass('hide');
    $("#invoice_item_info_" + id).removeClass('hide');
    $("#invoice_item_without_vat_price_" + id).removeAttr('disabled');
    $("#invoice_item_with_vat_price_" + id).removeAttr('disabled');
    $("#invoice_item_date_" + id).removeAttr('disabled');
  });

  $(document).on("click", ".invoice_item_cancel", function(){
    var id = getId(this.id);
    $("#invoice_item_info_" + id).addClass('hide');
    $(".invoice_item_edit").removeClass("hide");
  });

  $(document).on("click", ".drop_down_menu", function(){
    var parentEl = $(this).parent().closest('.drop_down_data_list');
    $(parentEl).find('.set_title_name').toggleClass("hide");
    $(parentEl).find('.show_upside_arrow').toggleClass("hide");
    $(parentEl).find('.display_drop_down_data').toggleClass("hide");
  });

  $("#print_all_invoice").on("click",function(){
    if($(this).is(':checked')) {
      $(".confirm_date_range").addClass("hide");
    }else{
      $(".confirm_date_range").removeClass("hide");
    }
  });

  $("#print_additional_information").on("click",function(){
    if($(this).is(':checked')) {
      $("additional_info_of_each_row").addClass("hide");
    }else{
      $("additional_info_of_each_row").removeClass("hide");
    }
  });

  $("#print_invoice_confirmed").on("click",function(){
    if($(this).is(':checked')) {
      $(".backgroung_color_select").addClass("btn-primary").removeClass("btn-secondary");
    }else{
      $(".backgroung_color_select").removeClass("btn-primary").addClass("btn-secondary");
    }
  });

  $(document).on("click", ".invoice_customer_check", function(){
    var id = getId(this.id);
    if ($("#customer_invoice_check_" + id).is(":checked")) {
      //add to the actual param
      $(".invoice_customer_check").prop('checked', false);
      $(this).prop('checked', true);
      $('.select_text').removeClass('hide');
      $('.unselect_text').addClass('hide');
      set_unselect_text("#customer_select_text_" + id, "#customer_unselect_text_" + id);
    }
    else {
      set_select_text("#customer_select_text_" + id, "#customer_unselect_text_" + id);
    }
  });

  $(document).on("click", ".invoice_expert_checkbox", function(){
    var id = getId(this.id);
    if ($("#expert_invoice_check_" + id).is(":checked")) {
      //add to the actual param
      $(".invoice_customer_check").prop('checked', false);
      $(this).prop('checked', true);
      $('.select_text').removeClass('hide');
      $('.unselect_text').addClass('hide');
      set_unselect_text("#expert_select_text_" + id, "#expert_unselect_text_" + id);
    }
    else {
      set_select_text("#expert_select_text_" + id, "#expert_unselect_text_" + id);
    }
  });

});



//show unselect text, hide select text
function set_unselect_text(selectId, unselectId) {
  $(selectId).addClass('hide');
  $(unselectId).removeClass('hide');
}

//show select text, hide unselect text
function set_select_text(selectId, unselectId) {
  $(selectId).removeClass('hide');
  $(unselectId).addClass('hide');
}

function getId(id){
  var idArr = id.split("_");
  return idArr[idArr.length - 1];
}