// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

import jquery from 'jquery';
window.$ = window.jquery = jquery;

import "bootstrap";
require("@nathanvda/cocoon")

import I18n from 'i18n-js'
window.I18n = I18n
require('i18n/translations')

require('website/acommon')
require('website/user')
require('website/office')
require('website/filter')
require('website/service')
require('website/maps')
require('website/message')
require('website/document_group')
require('website/link_library')
require('website/assignment')
require('website/agreement')
require('website/signature')
require('website/biller')
// require('bootstrap-datepicker')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require("trix")
require("@rails/actiontext")