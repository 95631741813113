$(document).ready(function(){

  $(document).on('click keyup change', '.person_share', function(){
    var person_share = $(this).val();
    $(".office_share").val(100 - person_share);
  });

  $(document).on('click keyup change', '.office_share', function(){
    var office_share = $(this).val();
    $(".person_share").val(100 - office_share);
  });

  $('.img-wrap .close').on('click', function() {
      alert('remove picture: ');
  });

  $(document).on("click", ".speciality_confirm", function(){
    var id = getId(this.id);
    //if checked
    if ($(this).is(":checked")){
      // update to unselect text
      set_unselect_text("#select_text_" + id, "#unselect_text_" + id);
    }
    else {
      // update to select text
      set_select_text("#select_text_" + id, "#unselect_text_" + id);
    }
  });

  $(document).on('click', '.speciality_edit', function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#speciality_name_" + id).removeAttr('disabled');
    $("#speciality_" + id).removeClass('hide');
    $("#speciality_save_" + id).removeClass('hide');
  });

  $(document).on('click', '.speciality_remove', function(){
    var id = getId(this.id);
    $("#" + id).remove();
  });

  $(document).on('click', '.speciality_delete', function(){
    var id = getId(this.id);
    $.ajax({
      url: '/specialities/'+id,
      method: 'DELETE',
      success: function(result){
        $("#speciality_" + id).remove();
      }
    }); 
  });

  $(document).on("click", ".edit_speciality", function(){
    var id = getId(this.id);
    $("#speciality_name_" + id).removeAttr('disabled');
    $("#speciality_description_" + id).removeAttr('disabled');
    $("#speciality_info_" + id).removeClass("hide");
  });

  $(document).on("click", ".payment_of_fees", function(){
    var payment_of_fees = $("input:radio.payment_of_fees:checked").val();
    if (payment_of_fees == "PERSONAL_ACCOUNT"){
      $(".own_company_check").addClass("hide");
      $(".personal_account_check").removeClass("hide");
    } else if (payment_of_fees == "OWN_COMPANY") {
      $(".personal_account_check").addClass("hide");
      $(".own_company_check").removeClass("hide");
    }
  });

  $(document).on("click", ".relationship_type", function(){
    var relationship_type = $("input:radio.relationship_type:checked").val();
    if (relationship_type == "ENTERPRENEURS_PARTNER"){
      $(".check_relation_type").addClass("hide");
    } else {
      $(".check_relation_type").removeClass("hide");
    }
  });

  $(".linkedin-icon").click(function() {
    $("#facebook-form, #twitter-form, #youtube-form, #whatsapp-form, #skype-form, #instagram-form").addClass('hide');
    $("#linkedin-form").removeClass('hide');
  });

  $('.linkedin-btn').click(function(){
    $("#linkedin-form").addClass('hide');
  });

  $(".facebook-icon").click(function() {
    $("#linkedin-form, #twitter-form, #youtube-form, #whatsapp-form, #skype-form, #instagram-form").addClass('hide');
    $("#facebook-form").removeClass('hide');
  });

  $('.facebook-btn').click(function(){
    $("#facebook-form").addClass('hide');
  });  

  $(".twitter-icon").click(function() {
    $("#linkedin-form, #facebook-form, #youtube-form, #whatsapp-form, #skype-form, #instagram-form").addClass('hide');
    $("#twitter-form").removeClass('hide');
  });

  $('.twitter-btn').click(function(){
    $("#twitter-form").addClass('hide');
  });

  $(".youtube-icon").click(function() {
    $("#linkedin-form, #facebook-form, #twitter-form, #whatsapp-form, #skype-form, #instagram-form").addClass('hide');
    $("#youtube-form").removeClass('hide');
  });

  $('.youtube-btn').click(function(){
    $("#youtube-form").addClass('hide');
  });  

  $(".whatsapp-icon").click(function() {
    $("#linkedin-form, #facebook-form, #twitter-form, #youtube-form, #skype-form, #instagram-form").addClass('hide');
    $("#whatsapp-form").removeClass('hide');
  });

  $('.whatsapp-btn').click(function(){
    $("#whatsapp-form").addClass('hide');
  });  

  $(".skype-icon").click(function() {
    $("#linkedin-form, #facebook-form, #twitter-form, #youtube-form, #whatsapp-form, #instagram-form").addClass('hide');
    $("#skype-form").removeClass('hide');
  });

  $('.skype-btn').click(function(){
    $("#skype-form").addClass('hide');
  });

  $(".instagram-icon").click(function() {
    $("#linkedin-form, #facebook-form, #twitter-form, #youtube-form, #whatsapp-form, #skype-form").addClass('hide');
    $("#instagram-form").removeClass('hide');
  });

  $('.instagram-btn').click(function(){
    $("#instagram-form").addClass('hide');
  });

  $(document).on('click', '.show_image_loader', function(){
    $("#loader-image").show();
  });
});

function getId(id){
  var idArr = id.split("_");
  return idArr[idArr.length - 1];
}

//show unselect text, hide select text
function set_unselect_text(selectId, unselectId) {
  $(selectId).addClass('hide');
  $(unselectId).removeClass('hide');
}

//show select text, hide unselect text
function set_select_text(selectId, unselectId) {
  $(selectId).removeClass('hide');
  $(unselectId).addClass('hide');
}