$(document).ready(function() {

  $(document).on("click", ".check_own_liability", function(){
    var liability = $(this).val();
    var head_office_name = $("#office_info").find("#head_office_name").val();
    var website = $("#office_info").find("#head_office_website").val();
    var company_id = $("#office_info").find("#head_office_company_id").val();
    if (liability == "false"){
      $(".branch_office_name").val(head_office_name);
      $(".branch_website").val(website);
      $(".branch_company_id").val(company_id);
    }else{
      $(".branch_office_name").val("");
      $(".branch_website").val("");
      $(".branch_company_id").val("");
    }
  });

  $(document).on("click", ".contract_group_save", function(){
    var form = $(this).closest('.contract_group_form');
    var authenticity_token = $(form).find('#authenticity_token').val();
    var name = $(form).find('#contract_group_name').val();
    var contract_group_id = $(form).find('#contract_group_id').val();
    var office_id = $(form).find('#id').val();
    var obj = {};
    obj["name"] = name;

    $.ajax({
      type:'PUT',
      url:'/offices/' + office_id + '/update_contract_group/' + contract_group_id,
      data: { authenticity_token : authenticity_token, id : office_id, contract_group_id : contract_group_id, contract_group : obj },
      success:function(){
      }
    });
  });

  $(document).on("click", ".contract_save", function(){
    var id = getId(this.id);
    var form = $(this).closest('.contract_form');
    var authenticity_token = $(form).find('#authenticity_token').val();
    var contract_group_id = $(form).find('#contract_group_id').val();
    var name = $(form).find('.contract_name').val();
    var validity_start = $(form).find('.validity_start').val();
    var validity_end = $(form).find('.validity_end').val();
    var alarm_date = $(form).find('.alarm_date').val();
    var office_id = $(form).find('#id').val();
    var contract_id = $(form).find('#contract_id').val();
    var creator_id = $(form).find('#creator_id').val();
    var updater_id = $(form).find('#updater_id').val();
    var formData = new FormData();
    var images = $(form).find('.groupContractMedia')[0].files

    if (new Date(validity_end) <= new Date(alarm_date)){
      var msg = I18n.t("js.general.task.alarm_date_must_be_greater_or_equal_to_end_date")
      display_validation_text($("#contract_form_" + id),msg);
    }
    else {
      remove_validation_text($(".contract_form"));
      var obj = {};
      obj["name"] = name;
      obj["validity_start_date"] = validity_start;
      obj["validity_end_date"] = validity_end;
      obj["alarm_date"] = alarm_date;
      obj["creator_id"] = creator_id;
      obj["updater_id"] = updater_id;
      obj = JSON.stringify(obj);
      formData.append('attachment',images[0]);
      formData.append('authenticity_token',authenticity_token);
      formData.append('id',office_id);
      formData.append('contract_group_id',contract_group_id);
      formData.append('contract',obj);
      formData.append('contract_id',contract_id);

      $.ajax({
        type:'PUT',
        url:'/offices/' + office_id + '/update_contract/' + contract_id,
        processData: false,
        contentType: false,
        data: formData,
        success:function(){
        }
      });
    }
  });

  $(document).on("click", ".contract_group_create", function(){
    var form = $(this).closest('.contract_group_form');
    var authenticity_token = $(form).find('#authenticity_token').val();
    var element_id = $(form).find('#element_id').val();
    var name = $(form).find('#contract_group_name').val();
    var office_id = $(form).find('#id').val();
    var obj = {};
    obj["name"] = name;

    $.ajax({
      type:'POST',
      url:'/offices/' + office_id + '/create_contract_group',
      data: { authenticity_token : authenticity_token, element_id : element_id, id : office_id, contract_group : obj },
      success:function(){
      }
    });
  });

  $(document).on("click", ".contract_create", function(e){
    var form = $(this).closest('.contract_parent');
    var authenticity_token = $(form).find('#authenticity_token').val();
    var element_id = $(form).find('#element_id').val();
    var contract_group_id = $(form).find('#contract_group_id').val();
    var name = $(form).find('.contract_name').val();
    var validity_start = $(form).find('.validity_start').val();
    var validity_end = $(form).find('.validity_end').val();
    var alarm_date = $(form).find('.alarm_date').val();
    var office_id = $(form).find('#id').val();
    var creator_id = $(form).find('#creator_id').val();
    var updater_id = $(form).find('#updater_id').val();
    var formData = new FormData();
    var images = $(form).find('.groupContractMedia')[0].files

    if (new Date(validity_end) <= new Date(alarm_date)){
      var msg = I18n.t("js.general.task.alarm_date_must_be_greater_or_equal_to_end_date")
      display_validation_text($(".contract_parent"),msg);
    }
    else {
      remove_validation_text($(".contract_parent"));
      var obj = {};
      obj["name"] = name;
      obj["validity_start_date"] = validity_start;
      obj["validity_end_date"] = validity_end;
      obj["alarm_date"] = alarm_date;
      obj["creator_id"] = creator_id;
      obj["updater_id"] = updater_id;
      obj = JSON.stringify(obj);
      formData.append('attachment',images[0]);
      formData.append('authenticity_token',authenticity_token);
      formData.append('element_id',element_id);
      formData.append('id',office_id);
      formData.append('contract_group_id',contract_group_id);
      formData.append('contract',obj);

      $.ajax({
        type:'POST',
        url:'/offices/'+ office_id + '/create_contract',
        processData: false,
        contentType: false,
        data: formData,
        success:function(){
        }
      });
    }
  });

  //show contract group form on clicking edit group btn
  $(document).on("click", ".contract_group_edit", function(){
    var id = getId(this.id);
    $("#contract_group_form_" + id).removeClass('hide');
  });

  //hide contract group form on clicking cancel btn
  $(document).on("click", ".contract_group_cancel", function(){
    var id = getId(this.id);
    $("#contract_group_lbl_" + id).removeClass('hide');
    $("#contract_group_form_" + id).addClass('hide');
  });

  //remove the create contract_group/contract form when clicked on remove btn
  $(document).on("click", ".contract_group_remove", function(){
    $("#" + getId(this.id)).remove();
  });

  $(document).on('click', '.contract_remove', function(){
    var id = getId(this.id);
    $("#" + id).remove();
  });

  //enable filename box and show save,cancel and remove btns contract on clicking edit contract btn
  $(document).on("click", ".contract_edit", function(){
    var id = getId(this.id);
    $(this).addClass('hide');
    $("#contract_detail_" + id).removeClass("hide");
    $("#contract_form_" + id).removeClass("hide");
    $("#contract_remove_" + id).removeClass("hide");
    $("#contract_cancel_" + id).removeClass("hide");
  });

  // $(document).on("change", ".groupContractMedia", function(){
  //   var id = getId(this.id);
  //   $.each(this.files, function() {
  //     readURL(this, id);
  //   });
  // });

  $(document).on("change", ".separate_visiting_address", function(){
    var address_type = $(this).val();
    if (address_type == "true"){
      $('.visiting_address_info').removeClass('hide');
    }else{
      $('.visiting_address_info').addClass('hide');
    }
  });

  $(document).on("change", ".email_invoice_address", function(){
    var address_type = $(this).val();
    if (address_type == "true"){
      $('.invoice_address_info').removeClass('hide');
    }else{
      $('.invoice_address_info').addClass('hide');
    }
  });

  $(document).on("change", ".electronic_billing_address", function(){
    var address_type = $(this).val();
    if (address_type == "true"){
      $('.billing_address_info').removeClass('hide');
    }else{
      $('.billing_address_info').addClass('hide');
    }
  });

  if ($("#new_opening_hours").length > 0) {
    $("#monday_start_hour").focusout(function(){
      monday_start_hour = $(this).val();
      start_hours = $(".start_hour");
      for(i = 0; i < start_hours.length; i++) {
        $(start_hours[i]).val(monday_start_hour)
      }
    });

    $("#monday_start_minute").focusout(function(){
      monday_start_minute = $(this).val();
      start_minutes = $(".start_minute");
      for(i = 0; i < start_minutes.length; i++) {
        $(start_minutes[i]).val(monday_start_minute)
      }
    });

    $("#monday_end_hour").focusout(function(){
      monday_end_hour = $(this).val();
      end_hours = $(".end_hour");
      for(i = 0; i < end_hours.length; i++) {
        $(end_hours[i]).val(monday_end_hour)
      }
    });

    $("#monday_end_minute").focusout(function(){
      monday_end_minute = $(this).val();
      end_minutes = $(".end_minute");
      for(i = 0; i < end_minutes.length; i++) {
        $(end_minutes[i]).val(monday_end_minute)
      }
    });
  }

  $("#openingHoursBtn").click(function(){
    $("#opening-hours-loader").show();
  });

  $("#moreOptionsBtn").click(function(){
    $("#opening-hours-loader").show();
  });

  $("body").click(function(e){
    if (!((e.target.id == "office_joining_fee") || (e.target.type == "button"))) {
      $('.number-picker').hide();
    }
  });
});

function getId(id){
  var idArr = id.split("_");
  return idArr[idArr.length - 1];
}

function display_validation_text(element,msg){
  $(element).parent().closest('div').addClass('has-error');
  $(element).parent().closest('div').find('small:eq(0)').remove();
  $(element).parent().closest('div').find('i:eq(0)').remove();
  $(element).parent().closest('div').append('<i class="form-control-feedback fa fa-remove" style="top: 0px;"></i>'); 
  $(element).parent().closest('div').append('<small class="help-block">'+msg+'</small>');
}

function remove_validation_text(element){
  $(element).parent().closest('div').removeClass('has-error');
  $(element).parent().closest('div').find('small:eq(0)').remove();
  $(element).parent().closest('div').find('i:eq(0)').remove();
}